

.form-wrapper {
    background-color: #ff7200;
}


.reg-reg img{
margin-left: 900px;
width: 60%;
margin-top: -840px;
}

.form {
width: 30em;
height:35em;
border: 5px solid #000000; 
background-color: rgb(9, 31, 31);
border-radius: 1.25rem;
margin-left: 48.125rem;
margin-top: 3.75rem;

}

    .user-name {
        width: 380px;
        height: 55px;
        margin-left: -2px;
        text-align: center;
        font-size: 20px;
        margin-top:30px;
        font-family: sans-serif;
        border-right: none;
        border-left: none;
        color: #f8fafc;
        font-size: 23px;
        letter-spacing: 1px;
        margin-top:30px;
        font-family: sans-serif;
        }

.newtshirtlogoo-a {
   
    margin-left: 1400px;
    margin-top: -616px;
}

.user {
  margin-left: -30px;
  font-size: 25px;
}
  .add-name {
        width: 350px;
        height: 55px;
        margin-left: -2px;
        text-align: center;
        font-size: 20px;
        letter-spacing: 1px;
        margin-top:30px;
        font-family: sans-serif;
        background-color: rgb(255, 255, 255);
        background: transparent;
        border-bottom: 1px solid #ff7200;
        border-right: none;
        border-left: none;
        color: #000000;
        font-size: 23px;
        letter-spacing: 1px;
        margin-top:80px;
        font-family: sans-serif;
  }

  .add-form {
    background-color: rgb(9, 31, 31);
    width:550px;
    height:710px;
    margin-left: 770px;
    margin-top: 40px;
  }

   .add-form input{
    color: #fff;
  }

  .add-form h1 {
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .summit-btn {
    cursor: pointer;
    width: 110px;
    height: 50px;
    margin-top: -6px;
    background-color: rgb(0, 0, 0);
    color: #fff;
  }

    .summit-btn:hover {
    background-color: rgb(252, 250, 250);
    color: #000000;
  }

  .back-btn {
    cursor: pointer;
    width: 110px;
    height: 50px;
    margin-left: 18px;
    list-style-type: none;
    background-color: aqua;
    
  }

   .back-btn:hover {
   background-color: #cf0000;
   color: #fff;
  }
        
        .pass-word {
        width: 380px;
        height: 55px;
        font-size: 20px;
        text-align:center;
        margin-left: -2px;
        border-right: none;
        border-left: none;
        font-size: 25px;
        margin-top:30px;
        font-family: sans-serif;
        }
.log-reg {
 background-color: #000000;
    place-items: center;
    display: grid;
    height: 100%;
}

.reg-quotation {
    background-color: #1c1c1c;
    color: #7e7c7c;
    text-align: start;
}
.log-log {
    background-color: rgb(28, 101, 236);
    
    place-items: center;
    display: grid;
    height: 100%;
}
.back {
    text-decoration: none;
    font-size: 20px;
    color: #f8fafc;
}

.back:hover {
    text-decoration: underline;
}
.container {
max-width: 50.5rem;
padding: 0 1.25rem;
margin: 10.625rem auto;
}


.wrapp {
  width: 100%; 
  display: flex; 
  margin-top: -140px;
  background-color: #0f0f0f;
  border-radius: 10px;
}
.wrapp .title {
 line-height: 2rem;
 font-size: 25px;
 color: #fff;
 }

.wrapp form {
 padding: 30px 20px 25px 20px;
}
.wrapp form .row-row{
    height: 2.825rem;
    margin: 20px;
    margin-bottom: 15px;
    position: relative;
   }
   form .row-row input:focus {
       border: #db5000;
       box-shadow: inset 0px 0px 2px 2px rgba(241, 159, 6, 0.25);
   }
   .wrapp form .row-row input{
   height: 100%;
   width: 100%;
   outline: none;
   font-size: 23px;
   padding-left: 60px;
   border-radius: 5px;
   }

     .row-row .sublog{
    border: none;
    padding-right: 50px;
    background-color: rgb(32, 34, 36);
    color: #fff;
    cursor: pointer;
   }
   .row-row .sublog:hover{
   border: none;
   background-color: #000000;
   color: #ff7300;
   transition: 0.4s ease;
   }
   
   .wrapp form .pass{
    padding-top: 20px;
    margin: 13px;
    text-decoration: none;
    font-size: 24px;
   }
   .wrapp form .pass a{
    text-decoration: none;
   }
   .wrapp form .pass a:hover {
       text-decoration: underline;
   }
  
  

   .fauser{
    position: absolute;
    width: 47px;
    height: 100%;
    color: #fff;
    font-size: 60px;
    background: #000000;
    border: 1px solid #fdfcfb;
    display: flex;
    align-items: center;
    justify-content: center;
   }
        
.glojes-account{
 background-color: white;
 border-radius: 40px;   
 color: #db5000;
 padding: 10px;

}
.btn-form-log {
    width:200px;
    height:40px;
    background-color: #08f7e3;
    border: none;
    margin-top: 20px;
    font-size: 25px;
    text-transform: uppercase;
    border-radius: 10px;
    margin-left: 5px;
    cursor: pointer;
    color: #000000;
    transition: 0.4s ease;
}
.btn-form-log:hover {
    background-color: rgb(0, 0, 0);
    
    color: #ee5f00;
}
        .btn-form {
            width:200px;
            height:40px;
            background-color: #08f7e3;
            border: none;
            margin-top: 1px;
            font-size: 25px;
            text-transform: uppercase;
            border-radius: 10px;
            
            cursor: pointer;
            color: #000000;
            transition: 0.4s ease;
            }

.btn-form:hover {
    transform: scale(1.15);
    background-color: #db5000;
    color: rgb(0, 0, 0);
    }
       
          
        
    .reg-form {
       padding: 20px;
       margin-left: 45px;
      

    }

.gender-category {
    margin-left: 10px;
}



.gender-category label{
padding: 20px;
font-size: 25px;
color: #ffffff;
}
.gender-category label, .gender-category input {
cursor: pointer;
}



        .login {

            font-size: medium;
           

        }

        .form .link{
            font-family: arial, helvetica, sans-serif;
            padding-top: 20px;
            text-align: center;
            }

        .link-button {
           
            margin-left: 15px;
            margin-top: 20px;
            margin-right: 34px;
            list-style: none;
           

        }

    .link {
color:#fffdfd;
font-family: arial, helvetica, sans-serif;
text-align: center;
font-size: 23px;
margin-left: 20px;


    }


.with-andrew {
color: rgb(0, 147, 232);
text-decoration: none;
display: flex;
justify-content: center;
align-items: center;
border-radius: 20px;
 
}

.with-andrew:hover {
    text-decoration: underline;
}



.registration-form {
       background-color: rgb(7, 12, 12);
       width:30%;
       height: 140%;
       font-family: Verdana, Geneva, Tahoma, sans-serif;
       justify-content: space-between;
       align-items: center;
       margin-left: 698px;
    
        }

.registration-form h2 {
    color:#fffefe;
    font-family: Microsoft YaHei UI Light;
    font-size: 30px;
    
}

.homeform{
    margin-top: -3.125rem;
   
}

.rules-link-link {
    color: rgb(252, 247, 247);
    font-size: 30px;
    
}
.rules-link-link:hover {
    color: #da5802;
}

.alert p {
    color:#fdfafa;
    font-size: 23px;;
}

.image-display-before {
   margin-top: 8px;
   cursor: pointer;
   width: 130px;
   height: 110px;
   border-radius: 100%;
}

.image-display-after {
    margin-top: 8px;
    cursor: pointer;
    width: 130px;
    height: 110px;
    border-radius: 100%;
 }

.image-upload-btn {
    width: 170px;
    height: 40px;
    font-size: 25px;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgb(255, 145, 1);
     }
    
     .image-upload-btn:hover {
        background-color: #262121;
        color: aqua;
        margin-right: -19px;
     }


     .box-in {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        background-color: #000000;
    }
    
    .box {
      position: relative;
      width: 490px;
      height: 520px;
      background-color: #181717;
      margin: 10.625rem auto;
      border-radius: 8px;
    }
    
    .box form{
      align-items: center;
      inset: 4px;
      background-color: orangered;
      padding: 50px 40px;
      border-radius: 8px;
      z-index: 2;
      display: flex;
      flex-direction: column;
    }
    
    .box form h1{
    color: #000000;
    }
    
    .box form .inputbox{
      position: relative;
      width: 300px;
      margin-top: 35px;
    }
    
    .box form .inputbox input{
      position: relative;
      width: 100%;
      padding: 20px 10px 10px;
      background: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      color: #23242a;
      font-size: 1.3em;
      letter-spacing: 0.05em;
      transition: 0.5s;
      z-index: 10;
    }
    
    .box form .inputbox span
    {
      position: absolute;
      left: 0;
      padding: 20px 0px 10px;
      pointer-events: none;
      color: #000000;
      font-size: 1.2em;
      letter-spacing: 0.05em;
      transition: 0.5s;
    }
    
    .box form .inputbox input:valid ~ span,
    .box form .inputbox input:focus ~ span
    {
     color: #fff;
     margin-top: -0.45rem;
     font-size: 1.3em;
     transform: translateY(-34px);
    }
    
    .box form .inputbox i {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: #fff;
      border-radius: 4px;
      overflow: hidden;
      transition: 0.5s;
      pointer-events: none;
    }
    
    .box form .inputbox input:valid ~ i,
    .box form .inputbox input:focus ~ i
    {
      height: 44px;
    }
    
    .box form .links {
      display: flex;
      column-gap: 100px;
      font-size: 1.2em;
    }
    
    .box form .links a{
      margin: 10px 0;
      font: 0.75em;
      color: #000000;
      text-decoration: none;
    }
    .box form .links a:hover{
     color: #fff;
     text-decoration: underline;
    }
    
    .box form input[type="submit"]
    {
      border: none;
      outline: none;
      padding: 9px 25px;
      background-color: #000000;
      color: #fff;
      cursor: pointer;
      font-size: 1.4em;
      border-radius: 4px;
      font-weight: 600;
      width: 150px;
      margin-top: 10px;
    }
    .box form input[type="submit"]:hover
    {
     background-color: #fdfdfd;
     color: #000000;
     transform: scale(1.15);
     transition: 0.3s ease;
    }
    
    .box form input[type="submit"]:active
    {
     opacity: 0.8;
    }







    .promo-too {
      min-height: 80vh;
      width: 100%;
      background-position: center;
      background-size: cover;
      background-image: url(../images/crowww.jpg);
      background-repeat: no-repeat;
    }
    
  