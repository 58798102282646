@media (max-width: 991px) {
  .videorow-textbox {
    width: 60%;
  }

  .ment h1 {
    font-size: 50px;
  
  }

  .logstart h1{
    color: orangered;
    font-size: 3rem;
    font-family: Arial, Helvetica, sans-serif;
   }

   .dorcus-row .right .content {
  padding-left: 0;
 }
 .Sati-row .right .content {
  padding-left: 0;
 }
 .Kasselie-row .right .content {
  padding-left: 0;
 }
 .elkana-row .right .content {
  padding-left: 0;
 }
 .peter-row .right .content {
  padding-left: 0;
 }
 .glo-concert-btn {
  padding-left: 0;  
}

}
@media (max-width: 768px) {

 

  .logstart h1{
    color: orangered;
    font-size: 250%;
    font-family: Arial, Helvetica, sans-serif;
   }
   
   .logstart p{
    color: #fff;
    font-size: 1.4rem;
    margin-top: -5.3em;
   font-family: Arial, Helvetica, sans-serif;
   }

  .dorcus-row {
    width: 90%;
    margin-left: 2.3rem;
    grid-template-columns: 1fr;
   
}

.left-dor img{
  width: 70%;
  height: 100%;
  object-fit: cover;
  margin-left: 0.125rem;
  border: 5px solid #000000;  
}
.left-Sati img {
  width: 70%;
  height: 100%;
  object-fit: cover;
  margin-left: 0.125rem;
  border: 5px solid #000000;  
}

.summit-row {
  width: 80%;
  display: grid;
  margin-top: 11rem;
  margin-left: 2rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem 1.8rem;
 }




.glo-concert-btn {
  margin-left: 54rem;

}

 .homeform{
  margin-top: -570px;
  margin-left: 560px;
 }



}

@media only screen and  (max-width:320px) {
   .concert-content p{
  padding-top: 70px;
  font-size: 25px;
  width: 85%;
  margin-left: 50px;
}
}

@media (max-width: 2046px) {

  .major-rules {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
}



@media (max-width: 768px) {

.wrapper-crownn {
  width: 90%;
  grid-template-columns: 1fr;
}
.wrapper-crownn p{
  font-size: 1.5rem;
  line-height: 1.6rem;
  padding-bottom: 1rem;
}




  .summit-row {
    width: 90%;
    margin-left: 2.3rem;
    grid-template-columns: 1fr;
 }
 .concert-content p{
  padding-top: 70px;
  font-size: 25px;
  width: 85%;
  margin-left: 50px;
}

}

@media (max-width: 769) {
  .wrapper-crownn {
    margin-left: 20rem;
    }
}

@media (max-width: 1024px) {
  nav a {
    font-size: 8px;
   }
  


  .choir-tip {
    background-image: url(../images/whitesea.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 25px;
    margin-top: 1000px;
    }

  

      .reg-button {
        width: 100px;
        background-color: #000000;
        height: 40px;
        margin-left:250px;
        top: 250px;
        left:8px;
        border-radius: 20px;
        list-style: none;
      }


      .reg-btn-reg-now {
        font-size: 20px;
        text-decoration: none;
        color: #ec6403;
       
     }
    
      .Sign-btn {
        width: 100px;
        background-color: #000000;
        height: 40px;
        margin-left: -450px;
        top: 250px;
        left: 550px;
        border-radius: 20px;
        list-style: none;
      }
    
  

    .graceinvasion-box {
      width: 50%;
      margin-left: -1190px;
      justify-content: center;
      align-items: center;
      top:1280px;
      left:1376px;
    }

    .crownn-box {
      width: 50%;
      justify-content: center;
      align-items: center;
      margin-left: -1250px;
      top: 1900px;
      left: 1434px;
    }

 

  .reg-box {
    margin-top: 2500px;
    margin-left: -509px;
  }

  .text-box-box-box p {
   font-size: 18px;
  
  }

 


}


@media (max-width: 1024px)
{

 .form {
margin-top: 600px;
margin-left: -476px;

}
  

.rules-row {
  padding-top: 10px;
  width: 90%;
  display: flex;
  justify-content: center;
  column-gap: 140px;
  padding-top: 1.34%;
  margin: auto;
}
   
.wrapper {
  width: 90%;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: -600px;
}

.wrapper-crownn {
width: 90%;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.wrapper-graceinvasion {
width: 90%;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.wrapper-joh {
 width: 90%;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.wrapper h1 {
  font-size: 42px;
  }

  .schedule-wrapper {
  background-color: #000000;
  margin-top: 2390px;
}

  .wrapper-concert {
  background-color: #e1e8ec;
  width: 600px;

  margin-left:250px;
  top: 420px;
  left:-150px;
  border: 5px solid #fffefe;
}

.wrapper-side-two-concert {
  background-color:  #e1e8ec;
  width: 600px;
 
  margin-left: -660px;
  margin-top: 1060px;
  border: 5px solid #fffefe;
}

  .christinaa2 {
    width: 300px; 
    height: 378px;
    margin-left: 40px;
    border: 5px solid #2a241e; 
    border-radius: 30px;
    margin-top: 40px;
   
    }

    .johnetr {
      max-width: 600px; 
      height: 400px;
      float: inline-start; 
      border: 5px solid #2a241e; 
      border-top-left-radius: 90px;
      border-bottom-right-radius: 90px;
      margin-right: 20px;
      margin-top: 40px;
      
  }


.top-glojes h1 {
  font-size: 42px;
}

.choir-tip {
 margin-top: 100px;
}
.wrapper-crownn {
  margin-top: 50px;
}
.wrapper-graceinvasion {
  margin-top: 50px;
}

}

@media only screen and (max-width: 768px){
  header .nav-btn {
      visibility: visible;
      background-color: #000000;
      opacity: 1;
  }
 
  .signupup {
    width: 40%;
  }
  
  header nav {
      position: fixed;
      top: 0;
      left: 0;
      padding: 40px;
      height: 50%;
      width:  100%;
      display: flex;
      flex-direction: column;
      margin-right: 60px;
      align-items: center;
      justify-content: center;
      background-color: orangered;
      transform: translateY(-100vh);
      }

      .header {
       color: #ee7621;

      }


     header .responsive_nav{
          transform: none;
          
      }

      nav .nav-close-btn {
               position: absolute;
               top: 1rem;
               right: 2rem;
              
       }

       nav a {
        font-size: 18px;
       }

    }


    @media (max-width: 991px)
    {
      .contact {
        padding: 50px;
      }
      .container-ner {
       flex-direction: column;
      }
      .container-ner .contact-info{
        margin-bottom: 40px;
       }
       .container-ner .contact-info,
       .contact-form
       {
        width: 100%;
       }
    }
    
    @media (max-width: 1119px) {
      nav a {
        list-style:none;
        margin-left: 18px;
        margin-top: 20px;
        font-size: 18px;
        }
    }
    @media (max-width: 1171px) {
      nav a {
        list-style:none;
       
        font-size: 18px;
        }
    }

    @media (max-width: 600px) { 
      .announce-row {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .con-row {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .tour-row {
        display: flex;
        align-items: center;
        flex-direction: column;
        }

      .directors-row {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .rules-row {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      

      .signupup {
        width: 40%;
      }

      
}
@media (max-width: 1334px) {
  .announce-row {
    display: flex;
    flex-direction: column;
  }

  .dirlink {
    text-decoration: none;
    font-size: 15px;
  }




  .aboutabout {
    margin-left: -1rem;
    font-size: 13px;
     text-align: start;
   }

  .guidelines-text-box {
    width: 90%;
    margin: auto;
    text-align: start;
    font-family: sans-serif;
    margin-top: 2rem;
    font-size: 15px;
    background-color: white;
    color: rgb(0, 0, 0);
  }

  .rulesrow-text-box {
    text-align: start;
    font-family: sans-serif;
    margin-top: 2rem;
    font-size: 16px;
    background-color: white;
    color: rgb(0, 0, 0);
  }
 
  
  .tour-row {
    display: flex;
    flex-direction: column;
   padding: 20px;
    width: 80%;
   
    }

  .con-row {
    display: flex;
    flex-direction: column;
  } 
  .rowling {
   width: 90%;
   grid-template-columns: repeat(2, 1fr);
  }
  .additionright{
    width: 100%;
    
   }
  .videorow {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

.major-rules {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}


  .summitrowling {
    width: 90%;
    grid-template-columns: repeat(2, 1fr);
   }
  .directors-row {
    display: flex;
    flex-direction: column;
  }

  .rules-row {
    display: flex;
    flex-direction: column;
  }

  .conpops {
    padding: 30px;
  }

  .announce-two {
    padding: 30px;
  }
  .announce-two img {
    width: 100%;
    height: 100%; 
}
.conpops img {
  width: 80%;
  height: 40%; 
}
.directors-two img {
    width: 20%;
    height: 10%; 
    border-radius: 100px;
}



.guidelines{
  width: 100%;
  margin: auto;       
} 


}


    @media (min-width: 601px) {
      .announce-row {
        width: 96%;
        align-items: center;
        }

       
        

        .con-row {
          width: 70%;
          align-items: center;
          }

    }
    @media (max-width: 991px) {
      .rowling .rowlingright .content{
      padding: 0;
      }
      .summitrowling .summitrowlingright .content{
        padding: 0;
        }
        

    }
    @media (max-width: 768px) { 

    
      .rowling {
        width: 90%;
        grid-template-columns: 1fr;
       
        }

        .guidelines-text-box {
          width: 90%;
          margin: auto;
          text-align: start;
          font-family: sans-serif;
          margin-top: 2rem;
          font-size: 15px;
          background-color: white;
          color: rgb(0, 0, 0);
        }

        .summitrowling {
          width: 90%;
          grid-template-columns: 1fr;
         
          }

          .summitrowlingleft img {
            margin-left: -2.3rem;
        }
    }

    @media (min-width: 767px) { 
      .signupup {
        width: 50%;
        }

        .promo-too {
          min-height: 80vh;
          width: 100%;
        }
    }
    @media (min-width: 1292px) { 
      .signupup {
        width: 20%;
        
       
        }
    }
    @media (min-width: 349px) { 
      .hometext-box p{
        width: 90%;
        }

        .promo-too {
          min-height: 80vh;
          width: 100%;
        }
    }

    @media (min-width: 704px) { 
     
        .promo-too {
          min-height: 80vh;
          width: 100%;
        }
    }

    @media (min-width: 1326px) { 
      .hometext-box p{
        width: 50%;
        }

       


        .guidelines-text-box {
          width: 90%;
          margin: auto;
          text-align: start;
          font-family: sans-serif;
          margin-top: 2rem;
          font-size: 1.34rem;
          background-color: white;
          color: rgb(0, 0, 0);
        }
    }

    @media (max-width: 726px) { 
      .wrapp {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .one-one-text-box h1 {
        font-size: 1.5rem;
      }
      
      .one-one-text-box p {
        font-size: 15px;
      }
      
      .two-text-box h1 {
        font-size: 1.5rem;
      }
      .three-text-box h1 {
        font-size: 1.5rem;
      }

      .dirlink {
        text-decoration: none;
        font-size: 15px;
      }

      .ceos h4 {
        font-size: 20px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      
        margin-top: 2rem;
        
        background-color: white;
        color: rgb(0, 0, 0);
      }

      .tour-row {
        padding-top: 10px;
        width: 98%;
        display: flex;
        justify-content: center;
        column-gap: 140px;
        padding-top: 1.34%;
        margin: auto;
      }


      .aboutabout {
        margin-left: 3rem;
        font-size: 13px;
         text-align: start;
       }

    } 
    @media (max-width: 607px) { 

     


      .wrapp {
        width: 90%; 
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .aboutabout {
       font-size: 13px;
        text-align: start;
      }

      .about {
        width: 90%;
        align-items: center;
        padding: 2rem;
        margin: auto;
      }

      .songs-concert-content p {
        padding-top: 70px;
        font-size: 14px;
        width: 80%;
        margin-left: 50px;
      }

    }

    @media (max-width: 695px) {


      .rules-row {
        padding-top: 10px;
        width: 90%;
        display: flex;
        justify-content: center;
        column-gap: 140px;
        padding-top: 1.34%;
        margin: auto;
      }
      
      .guidelines-text-box {
        width: 90%;
        margin: auto;
        text-align: start;
        font-family: sans-serif;
        margin-top: 2rem;
        font-size: 15px;
        background-color: white;
        color: rgb(0, 0, 0);
      }

     .one-rule {
      width: 100%;
      margin-left: -43px;
      
    }

    .rulesrow-text-box {
      text-align: start;
      font-family: sans-serif;
      margin-top: 2rem;
      font-size: 13px;
      background-color: white;
      color: rgb(0, 0, 0);
    }
   
    .rules-icon {
      min-width: 20px;
      display: flex;
      height: 20px;
      background-color: #fa9420;
      color: rgb(0, 0, 0);
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 9px;
    }

 .one-rule p{
  font-size: 10px;
  width: 100%;
}

.promotext-box p{
  color: white;
  font-size: 10px;
  width: 100%;
  text-align: center; 
  margin: auto;
  font-family: Myanmar Text;
}

.hometext-box p{
  color: white;
  font-size: 22px;
  width: 95%;
  text-align: center; 
  margin: auto;
  font-family: Myanmar Text;
}

.hometext-box {
  padding-top: 30px;
}
    }

    @media (max-width: 966px) { 

    .hometext-box {
       padding-top: 30px;
     }
         }

 @media (max-width: 1706px) {  


  .adverth {
    margin: 8px;
    width: 95%;
    height: 40%; 
}


  .summitrowling .summitrowlingright .summitcontent p {
    font-size: 13px;
    text-align: start;
   
  }

  .promotext-box h1{
    color: white;
    font-size: 10px;
    padding: 5px;
    margin: auto;
    font-family: Futura Bk BT;
  }

          .hometext-box h1{
            width: 40%;
            color: white;
            font-size: 2.15rem;
            padding: -90px;
            margin: auto;
            font-family: Futura Bk BT;
          }

          .promo-too {
            min-height: 10vh;
            width: 100%;
          }

          .home-too {
            min-height: 60vh;
            width: 100%;
            background-position: center;
            background-size: cover;
            
            background-repeat: no-repeat; 
          
           
          }
          .hometext-box {
            padding-top: 10px;
          }

         

  }

  @media (max-width: 600px) {  

    .adverth {
      margin: 8px;
      width: 95%;
      height: 40%; 
  }

    

    .hometext-box p{
      color: white;
      font-size: 22px;
      width: 100%;
      text-align: center; 
      margin: auto;
      font-family: Myanmar Text;
    }

    .hometext-box h1{
      width: 40%;
      color: white;
      font-size: 2.15rem;
      padding: -90px;
      margin: auto;
      font-family: Futura Bk BT;
    }

    .home-too {
      min-height: 30vh;
      width: 100%;
    }

    .promo-too {
      min-height: 30vh;
      width: 100%;
    }

    .hometext-box {
      padding-top: 10px;
    }

    .signupup {
      width: 30%;
      height: 40px;
      margin: auto;
      font-size: 16px;
      background-color: #000000;
      cursor: pointer;
      color: #08f7e3;
      border-radius: 10px; 
    }
  }

  @media (max-width: 400px) { 

   

    .major-rules {
      margin-left: 58px;
    }

    .con {
      background-color: #060311;
      color: #08f7e3;
      margin: auto;
      font-size: 12px;
    }

    .rls {
      background-color: #060311;
      color: #08f7e3;
      margin: auto;
      font-size: 12px;
    }

    .rule-textbox {
      width: 700px;
      justify-content: center;
      padding-left: -30px;
      text-align: start;
      display: flex;
      margin-left: -34px;
      background-color: #0c0427;
      color: white;
      font-size: 25px;
    }

    .rule-textbox:hover {
      background-color: #fffbfb;
      color: rgb(0, 0, 0);
      cursor: pointer;
      transform: scale(1.0);
      transition: 0.1s ease;
    }

    .home-too {
      height: 8vh;
      width: 100%;
    }

    .adverth {
      margin: 8px;
      width: 95%;
      height: 40%; 
  }

    .hometext-box p {
      color: white;
      font-size: 13px;
      width: 100%;
      font-family: Myanmar Text;
    }
  }


  @media (max-width: 644px) {  

    .con {
      background-color: #060311;
      color: #08f7e3;
      margin: auto;
      font-size: 12px;
    }

    .rls {
      background-color: #060311;
      color: #08f7e3;
      margin: auto;
      font-size: 12px;
    }


    .logstart h1{
      color: orangered;
      font-size: 150%;
      font-family: Arial, Helvetica, sans-serif;
     }
     
     .logstart p{
      color: #fff;
      font-size: 1.4rem;
      margin-top: -5.3em;
     font-family: Arial, Helvetica, sans-serif; 
     }
    
  }

  @media (min-width: 300px) {  

    .signlog {
      margin: 20px;
      font-size: 1rem;
      width: 30%;
      height: 40px;
     
      }

  }

  @media (min-width: 948px) {  

    .signlog {
      margin: 20px;
      font-size: 1rem;
      width: 20%;
      height: 40px;
     
      }

  }

  @media (min-width: 1384px) {  

    .signlog {
      margin: 20px;
      font-size: 1rem;
      width: 10%;
      height: 40px;
     
      }

  }

  @media (min-width: 898px) { 
    
 
   

  }

  @media (max-width: 894px) {  

    .con {
      background-color: #060311;
      color: #08f7e3;
      margin: auto;
      font-size: 12px;
    }

    .rls {
      background-color: #060311;
      color: #08f7e3;
      margin: auto;
      font-size: 12px;
    }


    .additionright .additioncontent p{
      font-family: sans-serif;
      text-align: start;
      font-size: 22px;
      padding: 30px;
      width: 80%;
      margin: auto;
    }
    
  }

  @media (max-width: 1782px) {  
    .scrollbar {
      background-color: #edf3f3;
      height: 300px;
      width: 80%;
      margin: auto;
      font-family: Arial, Helvetica, sans-serif;
      overflow: auto;
    }
    
  }
  
  @media (max-width: 1297px) {

    .about{
      width: 90%;
      margin: auto;
     }
  }
  
  @media (max-width: 682px) {

    .about{
      width: 90%;
      margin: -2px

     }
   
  }
  
  @media (max-width: 1417px) {

    .chrisprofile{
      width: 90%;
      margin: auto;
     }
     
     .andrewprofile{
      width: 90%;
      margin: auto;
     }
     
     .peterprofile{
      width: 90%;
      margin: auto;
     }

     .elknaprofile{
      width: 90%;
      margin: auto;
     }
  }

  
@media (max-width: 490px) {

  .ment h1 {
    font-size: 30px;
  
  }

  .summitrowling .summitrowlingright .summitcontent p {
    font-size: 13px;
    text-align: start;
   
  }


}

@media (max-width: 767px) {

  .tab {
    width: 100%;
    }

    .hometext-box p {
      color: white;
      font-size: 13px;
      width: 100%;
      font-family: Myanmar Text;
    }

    .hometext-box h1 {
      width: 60%;
      color: white;
      font-size: 1.75rem;
      padding: -90px;
      margin: auto;
      font-family: Futura Bk BT;
    }

    
}


@media (max-width: 760px) { 

  .probox {
    
    padding: 3px;
  } 
  

}

@media (max-width: 648px) { 

  .probox {
    max-width: 89%;
    padding: 2px
  }  
}



@media (max-width: 1846px) { 

  .directors img {
    width: 25%;
}

  .chrisprofile p{
    width: 95%;
    margin: auto;
    text-align: start;
    font-size: 14px;
    color: rgb(0, 0, 0);
  }

  .con-text-box {
    text-align: start;
    font-family: sans-serif;
    margin-top: 2rem;
    font-size: 14px;
    background-color: white;
    color: rgb(0, 0, 0);
  }

  


  .propro h2{
    font-size: 1.9rem;
    padding: 60px;
    margin-left: 9px;
   } 

   .avaimage {
    margin-left: 5.1rem;
    margin-top: -50px;
  }
    
  .proname {
    color: #08f7e3;
    font-size: 1.4rem;
    margin-left: 38px;
  } 
}

@media (max-width: 1590px) { 

.proname {
    color: #08f7e3;
    font-size: 1rem;    
    margin-left: 38px;
  } 
}

@media (max-width: 1610px) { 

  .avaimage {
    margin-left: 12px;
    margin-top: -50px;
  }

  .proname {
      color: #08f7e3;
      font-size: 12px;    
      margin-left: 8px;
    } 

    .propro h2{
      font-size: 15px;
      padding: 60px;
      margin-left: -50px;
     } 
     .email {
      font-size: 15px;
     
     }
  }

  
  @media (min-width: 767px) {
    .hometext-box {
      background-image: linear-gradient(rgb(21, 2, 39), rgba(0, 0, 0, 0.815));
      padding-top: 10px;
    }
   }
  
@media (max-width: 886px) { 

  .hometext-box {
    background-color: transparent;
    height: 60vh;
    padding-top: 10px;
  }

  .text p {
    font-size: 15px;
  }

  .promo-too {
    border: 6px solid rgb(212, 99, 0);
    margin-top: 20px;
  }

  .rowling .rowlingleft img{ 
    padding: 20px; 
   margin: auto;
    margin-top: -60px;
    width: 100%;
    height: 100%;
    
  }

  .avaimage {
    margin-left: 12px;
    margin-top: -50px;
  }

  .ment h1 {
    font-size: 34px;
    padding: 30px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  .ment p {
    font-size: 12px;
  }

  .homemar {
    background-image: linear-gradient(rgba(26, 1, 1, 0.7), rgba(18, 1, 34, 0.911));
    margin-top: -10px;
   }

   .hometext-box p {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    color: white;
    font-size: 20px;
    width: 50%;
    text-align: center;
    margin-top: 110px;
    font-family: Myanmar Text;
  }

  .hometext-box {
    background-color: transparent;
    padding-top: 10px;
  }

  .hometext-box p {
    color: white;
    font-size: 13px;
    width: 100%;
    
   
    font-family: Myanmar Text;
  }

  .hometext-box h1 {
    width: 60%;
    color: white;
    font-size: 1.75rem;
    padding: -90px;
    margin: auto;
    font-family: Futura Bk BT;
  }


  .proname {
      color: hsl(175, 94%, 50%);
      font-size: 18px;    
      margin-left: 8px;
    } 

    .propro h2{
      font-size: 18px;  
      padding: 60px;
      margin-left: -50px;
     } 
     .email {
      font-size: 19px;
     
     }

     .probox {
      background-color: #060311;
      margin-top: 40px;
      padding: 20px;
      margin-left: 2px;
      max-width: 100%;
    }

    .safe img {
      border: 2px solid orangered; 
      width: 60%;
      margin: auto;
      margin-top: 4rem;
  }

  }

  @media (max-width: 886px) { 

    .promo-too {
      border: 6px solid rgb(212, 99, 0);
      margin-top: 20px;
    }

    .avaimage {
      margin-left: 12px;
      margin-top: -50px;
    }

    .con-row {
      padding-top: 10px;
      width: 95%;
      display: flex;
      justify-content: center;
      column-gap: 140px;
      padding-top: 1.34%;
      margin: auto;
    }
  
    .proname {
        color: hsl(175, 94%, 50%);
        font-size: 18px;    
        margin-left: 8px;
      } 
  
      .propro h2{
        font-size: 18px;  
        padding: 60px;
        margin-left: -50px;
       } 
       .email {
        font-size: 19px;
       
       }
  
       .probox {
        background-color: #060311;
        margin-top: 40px;
        padding: 20px;
        margin-left: 2px;
        max-width: 100%;
      }
  
      .safe img {
        border: 2px solid orangered; 
        width: 80%;
        margin: auto;
        margin-top: 4rem;
    }
  
    }

    @media (max-width: 886px) {

      .promo-too {
        border: 6px solid rgb(212, 99, 0);
        margin-top: 20px;
      }

      .hometext-box p {
        background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
        color: white;
        font-size: 15px;
        width: 95%;
        text-align: center;
        margin-top: 230px;
        font-family: Myanmar Text;
      }


      .directors-one {
        background-color: #ffffff;
        width: 100%;
      }

      .directors img {
        width: 45%;
    }
    
      .chrisprofile p{
        width: 95%;
        margin: auto;
        text-align: start;
        font-size: 14px;
        color: rgb(0, 0, 0);
      }

      .con-text-box {
        text-align: start;
        font-family: sans-serif;
        margin-top: 2rem;
        font-size: 14px;
        background-color: white;
        color: rgb(0, 0, 0);
      }

    }

  @media (max-width: 604px) {

    .promo-too {
      border: 6px solid rgb(212, 99, 0);
      margin-top: 20px;
    }


    .hometext-box p {
      background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
      color: white;
      font-size: 20px;
      width: 50%;
      text-align: center;
      margin-top: 230px;
      font-family: Myanmar Text;
    }

   

    .container-ner .contact-info .box-box .text {
      display: flex;
      margin-left: 20px;
      font-size: 13px;
      color: #fff;
      flex-direction: column;
      font-weight: 200;
    }


    .container-ner .contact-info {
      width: 30%;
      margin-left: -12rem;
      display: flex;
      flex-direction: column;
    }

    .contact-form .inputbox input:focus ~ span,
    .contact-form .inputbox textarea:focus ~ span {
      color: #eb1102;
      margin-top: -1px;
      font-size: 13px;
      transform: translateY(-20px);
    }

    .contact-form .inputbox span {
      position: absolute;
      left: 0;
      font-size: 13px;
      padding: 5px 0;
      margin: -20px 0;
      pointer-events: none;
      transition: 0.6s;
      color: #067aac;
    }


    .hometext-box p {
      color: white;
      font-size: 13px;
      width: 100%;
      
     
      font-family: Myanmar Text;
    }

    .hometext-box h1 {
      width: 60%;
      color: white;
      font-size: 1.75rem;
      padding: -90px;
      margin: auto;
      font-family: Futura Bk BT;
    }

    .rowling .rowlingleft img{ 
      padding: 20px; 
     margin: auto;
      margin-top: -60px;
      width: 100%;
      height: 100%;
      
    }

    .con-text-box {
      text-align: start;
      font-family: sans-serif;
      margin-top: 2rem;
      font-size: 14px;
      background-color: white;
      color: rgb(0, 0, 0);
    }

    .additionright .additioncontent p {
      font-family: sans-serif;
      text-align: start;
      font-size: 22px;
      padding: 30px;
      width: 90%;
      margin: auto;
    }
    .con-row {
      padding-top: 10px;
      width: 95%;
      display: flex;
      justify-content: center;
      column-gap: 140px;
      padding-top: 1.34%;
      margin: auto;
    }

    .announce-row {
      width: 96%;
      align-items: center;
      }


   .announce-two img {
    max-width: 100%; 
}

.announce-one {
 width: 100%;
}

.announce-text-box {
  width: 100%;
  text-align: start;
  margin-top: 2rem;
  font-size: 14px;
  background-color: white;
  color: rgb(0, 0, 0);
}

.rowling .rowlingright .content p {
  font-size: 13px;
  text-align: start;
  
}

.rowling .rowlingright .content  {
margin-top: -10rem;
}
}

@media (max-width: 762px) {


 



  .rowling .rowlingright .content  {
    margin-top: -10rem;
    font-size: 13px;
    }
}

@media (max-width: 607px) { 

  .promo-too {
    border: 6px solid rgb(212, 99, 0);
    margin-top: 20px;
  }

  .directors-one {
    width: 450px;
    margin-left: -25px;
  }

  .directors img {
    width: 45%;
}

  .chrisprofile p{
    width: 95%;
    margin: auto;
    text-align: start;
    font-size: 14px;
    color: rgb(0, 0, 0);
  }

  .back {
    text-decoration: none;
    font-size: 14px;
    color: #f8fafc;
}

.promo-too {
  min-height: 20vh;
  width: 100%;
}

  .gender-category label{
    padding: 20px;
    font-size: 15px;
    color: #ffffff;
    }

  .wrapp .title {
    line-height: 2rem;
   font-size: 15px;
    color: #fff;
    }

    .wrapp form .row-row input{
      height: 100%;
      width: 100%;
      outline: none;
      font-size: 15px;
      padding-left: 60px;
      border-radius: 5px;
      }

  .wrapp {
    width: 90%; 
    display: flex; 
    margin-top: -140px;
    background-color: #0f0f0f;
    border-radius: 10px;
  }

  .box {
    position: relative;
    width: 380px;
    height: 520px;
    background-color: #181717;
    margin: 10.625rem auto;
    border-radius: 8px;
  }

  .box form h1{
    color: #000000;
    font-size: 23px;
    }

    .box form .links {
      display: flex;
      column-gap: 100px;
      font-size: 15px;
    }

    .box form input[type="submit"]
    {
      border: none;
      outline: none;
      padding: 9px 25px;
      background-color: #000000;
      color: #fff;
      cursor: pointer;
      font-size: 20px;
      border-radius: 4px;
      font-weight: 300;
      width: 150px;
      margin-top: 10px;
    }

    .box form .inputbox input{
      position: relative;
      width: 100%;
      padding: 20px 10px 10px;
      background: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      color: #23242a;
      font-size: 13px;
      letter-spacing: 0.05em;
      transition: 0.5s;
      z-index: 10;
    }

    .user {
      margin-left: -30px;
      font-size: 15px;
    }

}

@media (max-width: 385px) { 

  .promo-too {
    border: 6px solid rgb(212, 99, 0);
    margin-top: 20px;
  }

  .box {
    position: relative;
    width: 340px;
    height: 520px;
    background-color: #181717;
    margin: 10.625rem auto;
    border-radius: 8px;
  }

  .box form h1{
    color: #000000;
    font-size: 18px;
    }

    .box form .links {
      display: flex;
      column-gap: 100px;
      font-size: 10px;
    }

    .gender-category label{
      padding: 20px;
      font-size: 15px;
      color: #ffffff;
      }

    .box form input[type="submit"]
    {
      border: none;
      outline: none;
      padding: 9px 25px;
      background-color: #000000;
      color: #fff;
      cursor: pointer;
      font-size: 15px;
      border-radius: 4px;
      font-weight: 300;
      width: 150px;
      margin-top: 10px;
    }

    .box form .inputbox input{
      position: relative;
      width: 100%;
      padding: 20px 10px 10px;
      background: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      color: #23242a;
      font-size: 10px;
      letter-spacing: 0.05em;
      transition: 0.5s;
      z-index: 10;
    }

    .user {
      margin-left: -30px;
      font-size: 10px;
    }

}

@media (max-width: 419px) {



  .wrapp {

    .gender-category label{
      padding: 20px;
      font-size: 15px;
      color: #ffffff;
      }
   
    width: 98%; 
    display: flex; 
    margin-top: -140px;
    background-color: #0f0f0f;
    border-radius: 10px;
  }

 }

 @media (max-width:  361px) {

  .directors-one {
    width: 100%;
    margin-left: -20px;
   }

  .chrisprofile p{
    width: 90%;
    
    padding: 20px;
    text-align: start;
    font-size: 1.14rem;
    color: rgb(0, 0, 0);
  }

  .wrapp {
    width: 85%; 
    display: flex; 
    margin-left: 13px;
    margin-top: -140px;
    background-color: #0f0f0f;
    border-radius: 10px;
  }
 

 }

 
 @media (max-width:  769px) {

  .glosafe {
    width: 70%;
    margin-left: -20px;
}
} 

 @media (max-width:  774px) {

  .glosafe {
    width: 70%;
    margin-left: -20px;
}



.announce-two img {
  width: 238px;
  height: 310px; 
  box-shadow: 0 10px 8px rgb(22, 21, 20);
}



.announce-text-box h1 {
  text-align: start;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  margin-top: 2rem;
  font-size: 2.24rem;
  background-color: white;
  color: rgb(0, 0, 0);
}

.rowling .rowlingright .content p {
  font-size: 15px;
  text-align: start;
  padding-bottom: 15px;
}

.announce-text-box {
  text-align: start;
  margin-top: 2rem;
  font-size: 14px;
  background-color: white;
  color: rgb(0, 0, 0);
}


.ceos h4 {
  font-size: 2rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  margin-top: 2rem;
  
  background-color: white;
  color: rgb(0, 0, 0);
}


.home-too {
  min-height: 30vh;
  width: 100%;
  
}

.signupup {
  border: none;
  width: 25%;
  height: 30px;
  margin: auto;
  font-size: 10px;
  background-color: #000000;
  cursor: pointer;
  color: #08f7e3;
  border-radius: 10px;
}

 }

 @media (min-width:  769px) {

  .glosafe {
    display: none;
}



 }



 




