.pianoman {
 width: 100%;
 height: 600px;
 filter: brightness(0.4);
 
}






.abdrewnewregistration {
 width: 1700px;
 height: 800px;

}
.singinggirl {

    width:420px;
    height:560px;
    border-radius: 20px;
    position: absolute;
    margin-left:40px;
    top:200px;
    left: 2px;

}

.orangevideo {
    width: 100%;
    border: 10px solid #000000; 
}

.peterr {

    width:420px;
    height:568px;
    border-radius: 20px;
    position: absolute;
    margin-left:279px;
    top:200px;
    left:1300px;
}
.chrissatisatic {
    max-width: 600px; 
    height: 400px;
    float: inline-start; 
 
    border-radius: 30px;
    margin-right: 15px;

    }

    .andrew-ke {
        max-width: 600px; 
        width: 700px;
        height: 800px;
        margin-top: -250px;
        margin-right: 800px;
    }

    .kasselie {
        max-width: 600px; 
        height: 400px;
        float: inline-start; 
      
        border-radius: 30px;
        margin-right: 200px;
    }

    .elkana-elkana {
        max-width: 600px; 
        height: 400px;
        float: inline-start; 
     
        border-radius: 30px;
        margin-left: 20px;
    }



.peterjohnson {
    max-width: 600px; 
    height: 400px;
    float: inline-start; 
  
    border-radius: 30px;
    margin-right: 15px;
}



.church {
   width: 330px;
   height: 359px; 
}

.one-one img {
    width: 330px;
    height: 449px; 
}

.directors img {
    margin: auto;
}



.directors-two img {
    box-shadow: 0 10px 8px rgb(22, 21, 20);
    width: 180px;
    height: 200px; 
}



.one-one img:hover {
    transform: scale(1.02);
    transition: 0.7s ease;
}
.announce-two img {
    width: 270px;
    height: 349px; 
    box-shadow: 0 10px 8px rgb(22, 21, 20);
}

.sponsors-two img {
    margin: 20px;
    width: 210px;
    height: 230px;
    
}


.addition-row-head img { 
    margin: 40px;
    width: 40%;
    height: 40%;
   
}

.adverth {
    margin: 40px;
    width: 95%;
    height: 40%; 
}
.advert img { 
    margin: 40px;
    width: 40%;
    height: 40%;
   
}
.rulespops img {
    box-shadow: 0 10px 8px rgb(22, 21, 20);
    width: 95%;
    height: 349px;
   
}
.conpops img {
    box-shadow: 0 10px 8px rgb(22, 21, 20);
    width: 100%;
    height: 349px; 
}

.register img {
    width: 18rem;
}

.guidelines img{
    box-shadow: 0 10px 8px rgb(22, 21, 20);
    width: 90%;
    margin: auto;       
  } 

  .directors img {
    margin-top: 80px;
    width: 380px;
}

.sopglo{
max-width: 700px; 
height: 400px;
float: inline-start;

border-radius: 30px;
margin-right: 15px;
}

.glosafe {
    width: 25%;
}

.youthlaptop {
    max-width: 600px; 
float: left; 

border-radius: 30px;
margin-right: 15px;
}


.logo {
width: 90px;
border-radius: 100px;
}

.newtshirtlogooo {
    width: 500px;
    height: 60%;
    
}
.kekelie {
    position: absolute;
    width: 20%;
    height: 645px;
    margin-left: -424px;
    top: 866px;
}

.left-dor img{
    width: 70%;
    height: 100%;
    object-fit: cover;
    margin-left: 90px;
   
}

.left-Sati img{
    width: 70%;
    height: 100%;
    object-fit: cover;
    margin-left: 90px;
  
}

.left-Kasselie img{
    width: 70%;
    height: 100%;
    object-fit: cover;
    margin-left: 90px;
    
}
.left-elkana img{
    width: 70%;
    height: 100%;
    object-fit: cover;
    margin-left: 90px;
    
}
.left-peter img{
    width: 70%;
    height: 100%;
    object-fit: cover;
    margin-left: 90px;
  
}
.rowling .rowlingleft img{  
    width: 100%;
    height: 100%;
    margin-top: -15px;
    padding-top: -40px;
  }

.left-summit img{
    width: 70%;
    height: 90%;
    object-fit: cover;
    margin-left: 90px;
  
}


.row-johnet .johnet-left img {
    width: 50%;
    height: 90%;
    object-fit: cover;
    
 
}

  .andrew-reg {
    width: 450px;
    height: 500px;
    margin-left: -920px;
    margin-top: -350px;
  }


    .reg {
        width: 600px;
        height: 800px;
        margin-right: 1400px;
        border-radius: 30px;
    }
    .reg-box {
        border-radius: 20px;
        position: absolute;
        margin-left: -1090px;
        top:365px;
        left:1100px;
       
    }


 

  

  .elkana {
        width: 290px;
        height: 300px;
        border-radius: 180px;
       
    }

    .sopsummitjuly {
        width: 30%;
    }

  
.youth-smile {
    width: 80%;
    margin-left: 158px;
    
}
    
.youth-left {
    margin-left: 1200px;
    margin-top: -994px;
}
  

    .youth{
         width: 470px;
        height: 520px;
        padding: 40px;
     }
    
.safe img {
    border: 2px solid orangered; 
    width: 40%;
    margin: auto;
    margin-top: 4rem;
}


