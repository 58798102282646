@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.pianoman {
 width: 100%;
 height: 600px;
 -webkit-filter: brightness(0.4);
         filter: brightness(0.4);
 
}






.abdrewnewregistration {
 width: 1700px;
 height: 800px;

}
.singinggirl {

    width:420px;
    height:560px;
    border-radius: 20px;
    position: absolute;
    margin-left:40px;
    top:200px;
    left: 2px;

}

.orangevideo {
    width: 100%;
    border: 10px solid #000000; 
}

.peterr {

    width:420px;
    height:568px;
    border-radius: 20px;
    position: absolute;
    margin-left:279px;
    top:200px;
    left:1300px;
}
.chrissatisatic {
    max-width: 600px; 
    height: 400px;
    float: inline-start; 
 
    border-radius: 30px;
    margin-right: 15px;

    }

    .andrew-ke {
        max-width: 600px; 
        width: 700px;
        height: 800px;
        margin-top: -250px;
        margin-right: 800px;
    }

    .kasselie {
        max-width: 600px; 
        height: 400px;
        float: inline-start; 
      
        border-radius: 30px;
        margin-right: 200px;
    }

    .elkana-elkana {
        max-width: 600px; 
        height: 400px;
        float: inline-start; 
     
        border-radius: 30px;
        margin-left: 20px;
    }



.peterjohnson {
    max-width: 600px; 
    height: 400px;
    float: inline-start; 
  
    border-radius: 30px;
    margin-right: 15px;
}



.church {
   width: 330px;
   height: 359px; 
}

.one-one img {
    width: 330px;
    height: 449px; 
}

.directors img {
    margin: auto;
}



.directors-two img {
    box-shadow: 0 10px 8px rgb(22, 21, 20);
    width: 180px;
    height: 200px; 
}



.one-one img:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    transition: 0.7s ease;
}
.announce-two img {
    width: 270px;
    height: 349px; 
    box-shadow: 0 10px 8px rgb(22, 21, 20);
}

.sponsors-two img {
    margin: 20px;
    width: 210px;
    height: 230px;
    
}


.addition-row-head img { 
    margin: 40px;
    width: 40%;
    height: 40%;
   
}

.adverth {
    margin: 40px;
    width: 95%;
    height: 40%; 
}
.advert img { 
    margin: 40px;
    width: 40%;
    height: 40%;
   
}
.rulespops img {
    box-shadow: 0 10px 8px rgb(22, 21, 20);
    width: 95%;
    height: 349px;
   
}
.conpops img {
    box-shadow: 0 10px 8px rgb(22, 21, 20);
    width: 100%;
    height: 349px; 
}

.register img {
    width: 18rem;
}

.guidelines img{
    box-shadow: 0 10px 8px rgb(22, 21, 20);
    width: 90%;
    margin: auto;       
  } 

  .directors img {
    margin-top: 80px;
    width: 380px;
}

.sopglo{
max-width: 700px; 
height: 400px;
float: inline-start;

border-radius: 30px;
margin-right: 15px;
}

.glosafe {
    width: 25%;
}

.youthlaptop {
    max-width: 600px; 
float: left; 

border-radius: 30px;
margin-right: 15px;
}


.logo {
width: 90px;
border-radius: 100px;
}

.newtshirtlogooo {
    width: 500px;
    height: 60%;
    
}
.kekelie {
    position: absolute;
    width: 20%;
    height: 645px;
    margin-left: -424px;
    top: 866px;
}

.left-dor img{
    width: 70%;
    height: 100%;
    object-fit: cover;
    margin-left: 90px;
   
}

.left-Sati img{
    width: 70%;
    height: 100%;
    object-fit: cover;
    margin-left: 90px;
  
}

.left-Kasselie img{
    width: 70%;
    height: 100%;
    object-fit: cover;
    margin-left: 90px;
    
}
.left-elkana img{
    width: 70%;
    height: 100%;
    object-fit: cover;
    margin-left: 90px;
    
}
.left-peter img{
    width: 70%;
    height: 100%;
    object-fit: cover;
    margin-left: 90px;
  
}
.rowling .rowlingleft img{  
    width: 100%;
    height: 100%;
    margin-top: -15px;
    padding-top: -40px;
  }

.left-summit img{
    width: 70%;
    height: 90%;
    object-fit: cover;
    margin-left: 90px;
  
}


.row-johnet .johnet-left img {
    width: 50%;
    height: 90%;
    object-fit: cover;
    
 
}

  .andrew-reg {
    width: 450px;
    height: 500px;
    margin-left: -920px;
    margin-top: -350px;
  }


    .reg {
        width: 600px;
        height: 800px;
        margin-right: 1400px;
        border-radius: 30px;
    }
    .reg-box {
        border-radius: 20px;
        position: absolute;
        margin-left: -1090px;
        top:365px;
        left:1100px;
       
    }


 

  

  .elkana {
        width: 290px;
        height: 300px;
        border-radius: 180px;
       
    }

    .sopsummitjuly {
        width: 30%;
    }

  
.youth-smile {
    width: 80%;
    margin-left: 158px;
    
}
    
.youth-left {
    margin-left: 1200px;
    margin-top: -994px;
}
  

    .youth{
         width: 470px;
        height: 520px;
        padding: 40px;
     }
    
.safe img {
    border: 2px solid orangered; 
    width: 40%;
    margin: auto;
    margin-top: 4rem;
}



body {
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  scroll-behavior: smooth;
}

.email {
  font-size: 18px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

:root {
  --mainColor: transparent;
  --mainColorLight: #29335c;
  --secondatyColor: #0b2b39;
  --textColor: #f6f4f2;
}

.nav-nav a {
  color: #000000;
}

header h3 {
  color: #ec6403;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #f6f4f2;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.9rem;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding: 10px;
  background-color: orange;
  box-shadow: 0 15px 20px rgb(160, 154, 149);
  margin-bottom: 0px;
}

.nav a:hover {
  color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 6px;
}

.nav a {
  font-size: 1.5rem;
}

nav a {
  list-style: none;
  margin-left: 18px;
  margin-top: 20px;
  font-size: 20px;
}

.dropdown-menu {
  list-style: none;
  color: #beced5;
  position: relative;
  padding: 2rem;
  cursor: pointer;
}

.dropdown-list-list {
  display: block;
  border: 2px solid #ef5d03;
  text-align: start;
  color: #000000;
  list-style-type: none;
  position: absolute;
  left: 0;
  width: 230px;
  background-color: #fffefe;
}

.dropdown-list-list li:hover {
  height: 20px;
  cursor: pointer;
}

.dropdown-list .Link {
  border: 1px solid gray;
}

.dropdown-pop {
  color: #5abde7;
}

.dropdown-list .Link:hover {
  border: 1px solid white;
}

nav a {
  text-decoration: none;
  color: #fcf8f8;
  font-family: arial;
  text-transform: uppercase;
}

nav a:hover {
  text-decoration: underline;
}
.startlog {
  background-color: #000000;
  width: 100%;
  height: 100vh;
}
.logstart h1 {
  color: orangered;
  font-size: 5rem;
  padding: 10rem;
  font-family: Arial, Helvetica, sans-serif;
}

.signlog {
  margin: 20px;
  font-weight: bold;
  font-size: 1.4rem;
  cursor: pointer;
  width: 10%;
  height: 40px;
  border: none;
  background-color: rgb(245, 139, 0);
}

.signlog:hover {
  background-color: rgb(255, 255, 255);
}

.logstart p {
  color: #fff;
  font-size: 2rem;
  margin-top: -5.3em;
  font-family: Arial, Helvetica, sans-serif;
}

.famusic {
  margin-top: 2rem;
  font-size: 10rem;
  color: orangered;
}
.con-content {
  background-color: rgb(6, 20, 20);
  color: #fff;
  height: 120px;
}

.dirlink {
  color: blue;
  text-decoration: none;
}
.dirlink:hover {
  text-decoration: underline;

}

.dir-content {
  background-color: rgb(6, 20, 20);
  color: #fff;
  height: 340px;
}
.dir-concert-content {
  padding-top: 70px;
  font-size: 25px;
  width: 40%;
  margin-left: 50px;
}
.songs-content {
  background-color: rgb(6, 20, 20);
  color: #fff;
  height: 340px;
}



.songs-concert-content p {
  padding-top: 70px;
  font-size: 19px;
  width: 40%;
  margin-left: 50px;
}

.concert-content p {
  text-align: start;
  padding-top: 30px;
  font-size: 25px;
  width: 40%;
  margin: auto;
}

.reg-content {
  background-color: #b9b7b3;
  height: 340px;
}
.reg-content p {
  background-color: #b9b7b3;
  height: 340px;
}

.login-log {
  font-size: 28px;
  width: 200px;
  height: 60px;
  background-color: #000000;
  color: #da6200;
  margin-left: -1500px;
  margin-top: 60px;
  cursor: pointer;
}

.login-log:hover {
  transition: 0.4s ease-in-out;
  background-color: #ebe6e6;
  color: #000000;
}

.signupup {
  border: none;
  width: 20%;
  height: 70px;
  margin: auto;
  font-size: 25px;
  background-color: #000000;
  cursor: pointer;
  color: #08f7e3;
  border-radius: 10px;
}

.signupup:hover {
  background-color: #fff;
  color: #000000;
  text-decoration: underline;
  transition: 0.3s ease-in-out;
}

.con-Sign-up:hover {
  transition: 0.4s ease-in-out;
  background-color: #ebe6e6;
  color: #000000;
}

.con-reg-btn:hover {
  transition: 0.4s ease-in-out;
  background-color: #ebe6e6;
  color: #000000;
}

.dorcus-row {
  width: 80%;
  display: grid;
  margin-top: 11rem;
  margin-left: 4rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem 1.8rem;
}

.dorcus-row .right {
  display: flex;
  align-items: center;
}

.dorcus-row .right .content {
  padding-left: 20px;
  background-color: rgb(215, 213, 212);
}

.dorcus-row .right .content p {
  font-size: 1.5rem;
  line-height: 1.6rem;
  padding-bottom: 1rem;
}

.Sati-row {
  width: 80%;
  display: grid;
  margin-top: 11rem;
  margin-left: 4rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem 1.8rem;
}
.Sati-row .right {
  display: flex;
  align-items: center;
}
.Sati-row .right .content {
  padding-left: 20px;
  background-color: rgb(215, 213, 212);
}
.Sati-row .right .content p {
  font-size: 1.5rem;
  line-height: 1.6rem;
  padding-bottom: 1rem;
}

.Kasselie-row {
  width: 80%;
  display: grid;
  margin-top: 11rem;
  margin-left: 4rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem 1.8rem;
}

.Kasselie-row .right {
  display: flex;
  align-items: center;
}

.Kasselie-row .right .content {
  padding-left: 20px;
  background-color: rgb(215, 213, 212);
}
.Kasselie-row .right .content p {
  font-size: 1.5rem;
  line-height: 1.6rem;
  padding-bottom: 1rem;
}

.elkana-row {
  width: 80%;
  display: grid;
  margin-top: 11rem;
  margin-left: 4rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem 1.8rem;
}

.elkana-row .right {
  display: flex;
  align-items: center;
}
.elkana-row .right .content {
  padding-left: 20px;
  background-color: rgb(215, 213, 212);
}

.elkana-row .right .content p {
  font-size: 1.5rem;
  line-height: 1.6rem;
  padding-bottom: 1rem;
}

.peter-row {
  width: 80%;
  display: grid;
  margin-top: 11rem;
  margin-left: 4rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem 1.8rem;
}

.peter-row .right {
  display: flex;
  align-items: center;
}

.peter-row .right .content {
  padding-left: 20px;
  background-color: rgb(215, 213, 212);
}

.peter-row .right .content p {
  font-size: 1.5rem;
  line-height: 1.6rem;
  padding-bottom: 1rem;
}

.summit-row {
  width: 80%;
  display: grid;
  margin-top: 11rem;
  margin-left: 4rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem 1.8rem;
}

.summit-row .left {
  overflow: hidden;
}

.summit-row .right .content p {
  font-size: 1.5rem;
  line-height: 1.6rem;
  padding-bottom: 1rem;
}

.summit-row .right .content {
  padding-left: 20px;

  background-color: rgb(215, 213, 212);
}

.glojes {
  color: #fe7300;
  background-color: rgb(0, 0, 0);
  width: 150px;
  font-size: 23px;
  height: 100px;
  text-align: center;
  padding-top: 10px;
  border-radius: 60px;
  border: 5px solid #ff8400;
}

.glo-too {
  min-height: 60vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(rgb(4, 9, 30, 0.7), rgb(4, 9, 30, 0.7)),
    url(/static/media/crow.c6dc1ab0.jpg);
}

.rules-too {
  min-height: 80vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(rgb(4, 9, 30, 0.7), rgb(4, 9, 30, 0.7)),
    url(/static/media/crowww.91a0cb4f.jpg);
}




.home-too {
  min-height: 80vh;
  width: 100%;
  box-shadow: 0 10px 8px rgb(22, 21, 20);
  background-position: center;
  background-size: cover;
  background-image: url(/static/media/crowww.91a0cb4f.jpg);
  background-repeat: no-repeat;

  transition: 6s;

  -webkit-animation-name: animate;

          animation-name: animate;
  animation-direction: alternate-reverse;
  -webkit-animation-play-state: running;
          animation-play-state: running;

  -webkit-animation-timing-function: ease-in-out;

          animation-timing-function: ease-in-out;
  -webkit-animation-duration: 30s;
          animation-duration: 30s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes animate {
  0% {
    background-image: url(/static/media/faith.3b76c101.jpg);
  }
  20% {
    background-image: url(/static/media/shm.3ae3bbdf.jpg);
  }
  50% {
    background-image:url(/static/media/crowww.91a0cb4f.jpg);
  }
  60% {
    background-image: url(/static/media/csm.410a8d3f.jpg);
  }
}

@keyframes animate {
  0% {
    background-image: url(/static/media/faith.3b76c101.jpg);
  }
  20% {
    background-image: url(/static/media/shm.3ae3bbdf.jpg);
  }
  50% {
    background-image:url(/static/media/crowww.91a0cb4f.jpg);
  }
  60% {
    background-image: url(/static/media/csm.410a8d3f.jpg);
  }
}

.concert-too {
  min-height: 80vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgb(4, 9, 30, 0.7)),
    url(/static/media/crow.c6dc1ab0.jpg);
}

.songs {
  text-decoration: none;
}
.songs:hover {
  text-decoration: underline;
}
.concerttext-box p {
  width: 60%;
  color: white;
  font-size: 26px;
  text-align: center;
  font-family: sans-serif;
  margin: auto;
}
.additionright .additioncontent {
  width: 100%;
}



.adverthead {
  color: #ffffff;
}

.upup .signupup {
  margin-top: 28rem;
}



.additionright .additioncontent p {
  font-family: sans-serif;
  text-align: start;
  font-size: 22px;
  padding: 30px;
  width: 50%;
  margin: auto;
}
.advertcontent {
  font-family: sans-serif;
  text-align: start;
  font-size: 22px;
  padding: 30px;
  width: 50%;
  margin: auto;
}

.advert-row {
  display: column;
  background-color: #000000;
}

.text-box p {
  width: 210px;
  color: white;
  font-size: 28px;
  text-align: center;
  margin-left: 890px;
  font-family: Myanmar Text;
}

.homepara {
  
  color: white;
  font-size: 20px;
  width: 50%;
  text-align: center;
  margin: auto;
  font-family: Myanmar Text;
}


.promo-too {
  border: 10px solid rgb(212, 99, 0);
  margin-top: 20px;
}


.promotext-box p {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  color: white;
  font-size: 20px;
  padding: 2px;
  width: 100%;
  text-align: center;
  margin: auto;
  font-family: Myanmar Text;
}


.promotext-box h1 {
  padding: 12px;
  color: white;
  font-size: 1.25rem;
  padding: -90px;
  margin: auto;
  font-family: Futura Bk BT;
}

.rules-textbox p {
  padding: 20px;
  color: white;
  font-size: 22px;
  width: 50%;
  text-align: center;
  margin: auto;
  font-family: Myanmar Text;
}

.rules-textbox h1 {
  color: white;
  font-size: 6rem;
  text-align: center;
  margin: auto;
  font-family: Myanmar Text;
}

.text-box h1 {
  color: white;
  margin-top: 78px;
  font-size: 1.75rem;
  font-family: Futura Bk BT;
}

.hometext-box h1 {
  width: 80%;
  color: white;
  font-size: 3.75rem;
  margin-top: -60px;
  margin: auto;
  font-family: Futura Bk BT;
}
.concerttext-box h1 {
  width: 20%;
  color: white;
  font-size: 3.75rem;
  margin: auto;
  font-family: Futura Bk BT;
}
.text-box h2 {
  color: white;
  font-size: 2.15rem;
  font-family: Humanst521 Lt BT;
}
.hometext-box h2 {
  color: white;
  font-size: 2.15rem;
  font-family: Humanst521 Lt BT;
}
.concerttext-box h2 {
  color: white;
  font-size: 2.15rem;
  font-family: Humanst521 Lt BT;
}

.text-box {
  padding-top: 130px;
}

.concerttext-box {
  padding-top: 130px;
}
.about {
  width: 60%;
  align-items: center;
  padding: 2rem;
  margin: auto;
}
.aboutabout {
 
  font-size: 1.5rem;
  text-align: start;
}

.aboutabout h1 {
  text-align: center;
  background-color: black;
  color: orangered;
}

.our-tour {
  margin-top: 7.67rem;
  font-size: 1rem;
}

.tour-row {
  padding-top: 10px;
  width: 80%;
  display: flex;
  justify-content: center;
  grid-column-gap: 140px;
  -webkit-column-gap: 140px;
          column-gap: 140px;
  padding-top: 1.34%;
  margin: auto;
}

.sectionrow {
  background-color: #110534;
  width: 100%;
  margin: auto;
  margin-top: 30px;
}
.summitsectionrow {
  background-color: #110534;
  width: 100%;
  margin: auto;
  margin-top: 30px;
}

.rowling {
  width: 80%;
  max-width: 1170px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px 30px;
  margin: auto;
}

.summitrowling {
  width: 80%;
  max-width: 1170px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px 30px;
  margin: auto;
}
.rowling .rowlingleft {
  padding: 60px;
  overflow: hidden;
}

.summitrowling .summitrowlingleft {
  padding: 60px;
  overflow: hidden;
}

.summitrowlingleft p {
  margin-left: 50px;
  color: white;
  font-size: 23px;
}

.rowling .rowlingright {
  display: flex;
  align-items: center;
}

.summitrowling .summitrowlingright {
  display: flex;
  align-items: center;
}
.summitrowling .summitrowlingright .summitcontent {
  color: white;
  padding: 20px;
}

.rowling .rowlingright .content {
  color: white;
  
}

.summitrowling .summitrowlingright .summitcontent {
  color: white;
  padding: 20px;
}
.rowling .rowlingright .content p {
  font-size: 20px;
  text-align: start;
  padding-bottom: 15px;
}

.summitrowling .summitrowlingright .summitcontent p {
  font-size: 20px;
  line-height: 32px;
  text-align: start;
  padding-bottom: 15px;
}
.search-search {
  padding: 25px;
}

.search-bar {
  width: 40%;
  height: 65px;
  background-color: white;
  border: 3px solid #fff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  margin: auto;
}
.search-bar input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 25px;
  color: #000000;
}
.search-bar input::-webkit-input-placeholder {
  color: #000000;
}
.search-bar input::placeholder {
  color: #000000;
}

.search-bar button {
  height: 40px;
  width: 100%;
  margin-left: 400px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
}
.search-bar button .FaSearch {
  font-size: 30px;
  color: #000000;
}

.one-one {
  padding: 20px;
}
.adv {
  color: #ffffff;
  
  width: 70%;
  justify-content: center;
  grid-column-gap: 140px;
  -webkit-column-gap: 140px;
          column-gap: 140px;
  padding-top: 1.34%;
  margin: auto;
}
.announce-row {
  padding-top: 10px;
  width: 70%;
  display: flex;
  justify-content: center;
  grid-column-gap: 140px;
  -webkit-column-gap: 140px;
          column-gap: 140px;
  padding-top: 1.34%;
  margin: auto;
}

.videorow-textbox {
  width: 90%;
}

.videorow {
  padding-top: 10px;
  width: 80%;
  display: flex;
  justify-content: center;
  grid-column-gap: 40px;
  -webkit-column-gap: 40px;
          column-gap: 40px;
  padding-top: 1.34%;
  margin: auto;
}
.videohead h1 {
  color: orangered;
  font-size: 60px;
  padding: 20px;
}

.videoplayer {
  background-color: #060311;
  margin: 20px;
}

.directors-pop {
  padding: 20px;
}
.directors-one {
 width: 100%;
}

.advert-one {
  width: 100%;
}
.ceos h4 {
  font-size: 4rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  margin-top: 2rem;
  
  background-color: white;
  color: rgb(0, 0, 0);
}

.crowd-grace {
  padding-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  grid-column-gap: 140px;
  -webkit-column-gap: 140px;
          column-gap: 140px;
  padding-top: 1.34%;
  margin: auto;
}

.guidelines {
  padding-top: 10px;
  width: 60%;
  justify-content: center;
  grid-column-gap: 140px;
  -webkit-column-gap: 140px;
          column-gap: 140px;
  padding-top: 1.34%;
  margin: auto;
}

.rule-textbox {
  width: 600px;
  justify-content: center;
  text-align: start;
  display: flex;
  margin-left: 20px;
  padding: 10px;
  background-color: #0c0427;
  color: white;
  font-size: 19px;
}

.rule-textbox:hover {
  background-color: #fffbfb;
  color: rgb(0, 0, 0);
  cursor: pointer;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 0.7s ease;
}

.rule-textbox p {
  padding: 20px;
}

.rules-icon {
  min-width: 60px;
  display: flex;
  height: 60px;
  background-color: #fa9420;
  color: rgb(0, 0, 0);
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 22px;
}

.major-rules-section {
background-color: #060311;
  width: 100%;
  margin: auto;
  margin-top: 30px;
  background-size: cover;
}

.con {
  background-color: #060311;
  color: #08f7e3;
  margin: auto;
  font-size: 30px;
}

.rls {
  background-color: #060311;
  color: #08f7e3;
  margin: auto;
  font-size: 30px;
}

.major-rules {
  padding: 70px;
  width: 30%;
  display: column;
  margin: auto;
}

.one-rule {
  display: flex;
}

.rules-row {
  padding-top: 10px;
  width: 70%;
  display: flex;
  justify-content: center;
  grid-column-gap: 140px;
  -webkit-column-gap: 140px;
          column-gap: 140px;
  padding-top: 1.34%;
  margin: auto;
}

.con-row {
  padding-top: 10px;
  width: 70%;
  display: flex;
  justify-content: center;
  grid-column-gap: 140px;
  -webkit-column-gap: 140px;
          column-gap: 140px;
  padding-top: 1.34%;
  margin: auto;
}

.directors-row {
  padding-top: 10px;
  width: 90%;
  display: flex;
  justify-content: center;
  grid-column-gap: 140px;
  -webkit-column-gap: 140px;
          column-gap: 140px;
  padding-top: 1.34%;
  margin: auto;
}
.announce-one {
  width: 100%;
}
.sponsors-one {
  width: 100%;
}
.sponsors-two-one {
  width: 100%;
}
.conrowrow-one {
  width: 100%;
}
.concert-one {
  width: 100%;
}

.directors-one {
  width: 100%;
}

.ment h1 {
  font-size: 64px;
  padding: 30px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.ment p {
  font-size: 1.34rem;
}

.guidelines-text-box {
  width: 64%;
  margin: auto;
  text-align: start;
  font-family: sans-serif;
  margin-top: 2rem;
  font-size: 1.34rem;
  background-color: white;
  color: rgb(0, 0, 0);
}
.guidelines-text-box h1 {
  text-align: center;
}

.announce-text-box h1 {
  text-align: start;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  margin-top: 2rem;
  font-size: 3.24rem;
  background-color: white;
  color: rgb(0, 0, 0);
}

.announce-text-box {
  text-align: start;
  margin-top: 2rem;
  font-size: 1.34rem;
  background-color: white;
  color: rgb(0, 0, 0);
}

.sponsors-text-box {
  font-family: sans-serif;
  margin-top: 2rem;
  font-size: 1.34rem;
}

.sponsors-two-text-box {
  font-family: sans-serif;
  margin-top: 2rem;
  font-size: 1.34rem;
}

.rulesrow-text-box {
  text-align: start;
  font-family: sans-serif;
  margin-top: 2rem;
  font-size: 1.34rem;
  background-color: white;
  color: rgb(0, 0, 0);
}
.con-text-box {
  box-shadow: 0 10px 8px rgb(65, 60, 56);
  text-align: start;
  font-family: sans-serif;
  margin-top: 2rem;
  font-size: 1.34rem;
  background-color: white;
  color: rgb(0, 0, 0);
}
.concert-text-box {
  text-align: start;
  font-family: sans-serif;
  margin-top: 2rem;
  font-size: 1.34rem;
  background-color: white;
  color: rgb(0, 0, 0);
}
.dirbox {
  margin: auto;
  padding: 30px;
  font-size: 1.34rem;
  color: rgb(0, 0, 0);
}

.chrisprofile p{
  width: 40%;
  margin: auto;
  padding: 30px;
  text-align: start;
  font-size: 1.34rem;
  color: rgb(0, 0, 0);
}

.andrewprofile {
  width: 40%;
  margin: auto;
  padding: 30px;
  text-align: start;
  font-size: 1.34rem;
  color: rgb(0, 0, 0);
}
.peterprofile {
  width: 40%;
  margin: auto;
  padding: 30px;
  text-align: start;
  font-size: 1.34rem;
  color: rgb(0, 0, 0);
}

.elknaprofile {
  width: 40%;
  margin: auto;
  padding: 30px;
  text-align: start;
  font-size: 1.34rem;
  color: rgb(0, 0, 0);
}
.dirbox h1 {
  margin: auto;
}

.directors-text-box {
  margin-top: 2rem;
  font-size: 1.34rem;
  background-color: white;
  color: rgb(0, 0, 0);
}

.one-one-text-box {
  background-color: #000000;
  color: orangered;
}

.one-one-text-box h1 {
  font-size: 2.34rem;
}

.one-one-text-box p {
  font-size: 1.34rem;
}

.two-text-box h1 {
  font-size: 2.34rem;
}
.three-text-box h1 {
  font-size: 2.34rem;
}

.glo-jesus {
  color: #09a29a;
  background-color: #021615;
  width: 767px;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  margin-top: -300px;
}

.summit-names {
  color: #000000;
}
.summit-names-names {
  padding-right: 1800px;
  margin-top: 5px;
}

.videorow-textbox {
  font-family: sans-serif;
  margin-top: 2rem;
  font-size: 1.34rem;
  color: white;
  text-align: start;
  margin: auto;
}

.sumit-names2 {
  padding-right: 1200px;
  margin-top: -310px;
}

.summit-last-list {
  margin-top: -310px;
}
.summit-names-names-names {
  padding-right: 1200px;
  margin-top: -310px;
  margin-left: 600px;
}

.summit-listing {
  margin-top: -230px;
  margin-left: 700px;
}

.summit-2022 {
  padding: 20px;
  color: white;
}

.summit-all-list {
  background-color: #000000;
  height: 600px;
  color: #e8d1d1;
  font-size: 20px;
}
.summit-rules p {
  margin-left: 150px;
  font-size: 20px;
  color: white;
  width: 60%;
}
.summit-rules-con {
  background-color: #000000;
}

.the-rules {
  font-size: 25px;
}

.text-profile {
  font-size: 23px;
}

.andrew-drew-profile {
  font-size: 23px;
}

.comments-all {
  font-size: 23px;
}
.about-one {
  margin-right: 1300px;
  font-size: 23px;
}

.about-two {
  position: absolute;
  margin-left: 400px;
  top: 243px;
  left: 800px;
  font-size: 23px;
}

.home-background {
  background-color: #f1e8e8;
}

.wrapper {
  margin: -250px auto;
  margin-top: -450px;
  width: 70%;
  margin-left: 320px;
}

.glo-glo {
  margin-top: 300px;
}
.wrapper p {
  background-color: #f4b803;
}

.wrapper-joh {
  margin: 445px auto;
  width: 70%;
  margin-left: 320px;
}

.wrapper4 {
  margin: 150px auto;
  width: 70%;
  margin-right: 285px;
}

.text-box {
  color: #000000;
}

.wrapper h1 {
  font-size: 80px;
}

.wrapper-joh h1 {
  font-size: 80px;
}

.wrapper-joh p {
  background-color: #f4b803;
  font-size: 25px;
}

.text-box-box p {
  color: rgb(0, 0, 0);
  font-size: 19px;
}
.text-box .propro {
  font-size: 62px;
}

.glo-wrapper h4 {
  color: #110534;
}

.wrapper p {
  font-size: 25px;
}
.text-box2 {
  color: #000000;
}

.text-box2 p {
  font-size: 22px;
}

.wrapper-2 {
  background-color: #ffffff;
  margin: 150px auto;
  margin-top: 500px;
  width: 70%;
  height: 405px;
  align-items: center;
}

.wrapper-2 p {
  font-size: 23px;
}

.wrapper-chris {
  background-color: #ebe6e6;
  margin: 145px auto;
  width: 40%;
  margin-left: 100px;
}
.wrapper-chris p {
  color: rgb(0, 0, 0);
  font-size: 26px;
}
.wrapper-chris h1 {
  color: rgb(0, 0, 0);
}

.wrapper-andrew {
  background-color: #ebe6e6;
  margin: 45px auto;
  width: 40%;
  margin-left: 100px;
}
.wrapper-andrew h1 {
  color: rgb(0, 0, 0);
}

.wrapper-andrew p {
  color: rgb(0, 0, 0);
  font-size: 26px;
}

.wrapper-peter p {
  color: rgb(0, 0, 0);
  font-size: 26px;
}

.wrapper-peter h1 {
  color: rgb(0, 0, 0);
}

.wrapper-peter {
  background-color: #ebe6e6;
  margin: 45px auto;
  margin-top: -1232px;
  width: 40%;
  margin-left: 1100px;
}

.wrapper-elkana {
  background-color: #ebe6e6;
  margin: 45px auto;
  margin-top: -725px;
  width: 40%;
  margin-left: 1100px;
}
.wrapper-elkana h1 {
  color: rgb(0, 0, 0);
}

.wrapper-elkana p {
  color: rgb(0, 0, 0);
  font-size: 25px;
}

.wrapper-concert {
  background-color: #e1e8ec;
  width: 900px;
  margin-left: 250px;
  margin-top: 178px;
  margin-left: 48px;
  border: 5px solid #fffefe;
}
.wrapper-concert p {
  font-size: 25px;
}

.schedule-wrapper {
  background-color: #000000;
  margin-top: 190px;
}

.wrapper-side-two-concert {
  background-color: #e1e8ec;
  width: 900px;
  margin-left: 250px;
  margin-top: -1075px;
  margin-left: 1060px;
  border: 5px solid #fffefe;
}

.col {
  width: 1800px;
  justify-content: space-between;
  margin-left: 120px;
  font-size: 20px;
  margin-top: 100px;
}
.activities {
  width: 1800px;
  justify-content: space-between;
  margin-left: 120px;
  font-size: 20px;
  margin-top: 100px;
  cursor: pointer;
}

.members-roll:hover {
  background-color: rgb(170, 175, 174);
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.activities-roll:hover {
  background-color: rgb(0, 0, 0);
  color: white;
  transition: 0.2s ease-in-out;
}

.one:hover {
  background-color: rgb(0, 0, 0);
  color: orange;
  transition: 0.2s ease-in-out;
}

.event-name:hover {
  background-color: rgb(218, 116, 0);
  color: rgb(0, 0, 0);
  transition: 0.2s ease-in-out;
}

.vanue:hover {
  background-color: rgb(218, 116, 0);
  color: rgb(0, 0, 0);
  transition: 0.2s ease-in-out;
}

.date:hover {
  background-color: rgb(218, 116, 0);
  color: rgb(0, 0, 0);
  transition: 0.2s ease-in-out;
}

.time:hover {
  background-color: rgb(218, 116, 0);
  color: rgb(0, 0, 0);
  transition: 0.2s ease-in-out;
}

.number:hover {
  background-color: rgb(248, 112, 0);
  color: rgb(0, 0, 0);
  transition: 0.2s ease-in-out;
}

.first-name:hover {
  background-color: rgb(0, 0, 0);
  color: orange;
  transition: 0.2s ease-in-out;
}
.tone:hover {
  background-color: rgb(0, 0, 0);
  color: orange;
  transition: 0.2s ease-in-out;
}
.number:hover {
  background-color: rgb(0, 0, 0);
  color: orange;
  transition: 0.2s ease-in-out;
}
.p-number:hover {
  background-color: rgb(0, 0, 0);
  color: orange;
  transition: 0.2s ease-in-out;
}

.your-account {
  margin-top: 50px;
  font-family: MV Boli;
}
.activities-tittle h1 {
  font-family: Microsoft YaHei UI Light;
}
.list {
  font-family: Microsoft YaHei UI Light;
  width: 450px;
  height: 70px;
  background-color: #000000;
  color: #feffff;
}
.activities-list {
  font-family: Microsoft YaHei UI Light;
  width: 400px;
  height: 70px;
  background-color: #9ba3a1;
  color: #000000;
}
.song-song-list {
  font-size: 25px;
  font-family: monospace;
  width: 450px;
  height: 70px;
  background-color: #000000;
  color: #fcfcfc;
}

.edit-btn {
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.edit-btn {
  display: flex;
}
.view {
  width: 100px;
  height: 50px;
  font-size: 20px;
  color: rgb(251, 248, 248);
  background-color: #000000;
  border-radius: 10px;
  cursor: pointer;
}
.edit {
  width: 100px;
  height: 50px;
  font-size: 20px;
  color: rgb(0, 0, 0);
  background-color: #bdcef0;
  border-radius: 10px;
  cursor: pointer;
}

.delect {
  width: 100px;
  height: 50px;
  font-size: 20px;
  color: white;
  background-color: #db0404;
  border-radius: 10px;
  cursor: pointer;
}
.wrapper-side-two-concert p {
  font-size: 25px;
}
.youth-wrapper4 {
  background-color: #0b2b39;
}

.wel {
  width: 100%;
  height: 100px;
  background-color: #f8dfc6;
}

.wel h1 {
  margin-right: 1600px;
  padding: 35px;
}

.wel button {
  width: 268px;
  height: 60px;
  background-color: #000000;
  border-radius: 10px;
  margin-left: 1640px;
  margin-top: 20px;
  color: #fffffe;
  font-size: 26px;
  cursor: pointer;
}
.wel button:hover {
  background-color: rgb(93, 255, 255);
  color: #000000;
}

.kesselie-a {
  margin-top: 20px;
}

.andrew-reg-link {
  background-color: #cdf7ed;
  margin-top: 150px;
}
.andrew-reg-link h1 {
  color: #000000;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.wrapper-choirlist {
  background-image: url(/static/media/crown.4c784fa9.jpg);

  background-size: cover;
  background-repeat: no-repeat;
}
.additonal-rules {
  background-image: url(/static/media/dackcgt.9b4704af.jpg);
}

.glo-wrapper {
  background-color: #c77c02;
  max-width: 90%;
  width: 15%;
  font-size: 50px;
  border: 5px solid #000000;
  margin-left: 2px;
  left: 100px;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  margin-top: -530px;
  left: 100px;
}

.glo-wrapper h1 {
  top: 200px;
  border: 5px solid #000000;
}

.sign-today {
  background-color: rgb(6, 20, 20);
  padding-top: rem;
}
.sign-up {
  width: 900px;
}

.glojes-name {
  color: #067aac;
}

.additonal-rules-text-box {
  font-size: 22px;
}

.additonal-rules-text-box p {
  color: #f6f2f2;
}

.additonal-rules-text-box h3 {
  color: #f6f2f2;
}

.icons-youtube {
  color: rgb(255, 254, 254);
  width: 80px;
  height: 30px;
  cursor: pointer;
}

.icons-facebook {
  color: rgb(255, 255, 255);
  width: 80px;
  height: 30px;
  cursor: pointer;
}
.icons-whatsapp {
  color: rgb(255, 255, 255);
  width: 80px;
  height: 30px;
  cursor: pointer;
}

.s-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.button-nav {
  background-color: #000000;
}

.glojes-button-name {
  color: #ffffff;
  font-size: 20px;
  padding: 50px;
}

.glojes-nav-but {
  background-color: #000000;
  height: 200px;
}

.top-glojes {
  background-color: #021615;
}

.top-glojes h1 {
  color: #067aac;
  font-size: 70px;
}
.top-glojes h2 {
  color: #dfecf2;
  font-size: 40px;
}

.top-glojes-glo {
  background-color: #000000;
  color: #067aac;
  font-size: 90px;
}

.text-box-box-box p {
  color: #e8d1d1;
  font-size: 24px;
}

.text-box-box p {
  color: #000000;
}

.face-tube {
  top: 100px;
}
.text-box .eedmondedmond-name {
  font-size: 35px;
}
.text-box .marinagono-name {
  font-size: 35px;
}
.eedmondedmond-wrapper {
  margin-right: 1640px;
}

.marinagono-wrapper {
  position: absolute;
  margin-left: 150px;
  top: 980px;
  left: 450px;
}

.scrollbar {
  background-color: #edf3f3;
  height: 300px;
  width: 40%;
  margin: auto;
  font-family: Arial, Helvetica, sans-serif;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 15px;
}
::-webkit-scrollbar-track {
  background-color: #d6d5d8;
}
::-webkit-scrollbar-thumb {
  background: #000000;

  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #000000;
}

.sponsorheading h1 {
  font-size: 39px;
  margin: 30px;
}
.sponsorheading p {
  font-size: 23px;
  text-align: start;
  width: 80%;
  margin: auto;
}
.people {
  width: 20%;
  height: 40%;
  background-color: #067aac;
  margin: auto;
}

.elkana-wrapper {
  position: absolute;
  margin-left: 150px;
  top: 980px;
  left: 980px;
}

.text p {
  font-size: 20px;
}

.text-box .elkana-name {
  font-size: 35px;
}

.rule-head {
  background-color: #067aac;
}

.sponsors-meet {
  background-image: url(/static/media/crowdcrowdcrowd.90fece54.jpg);
  width: 100%;
  height: 529px;
  background-repeat: no-repeat;
  background-size: cover;
}

.sponsors-meet h1 {
  background-color: #000000;
  color: #067aac;
}

.sponsors-meet {
  font-size: 30px;
  color: #beced5;
  box-shadow: 0 15px 20px rgb(240, 157, 89);
}

.button-button {
  position: absolute;
  margin-left: 250px;
  top: 1900px;
  left: -255px;
}

.award-text-box-box p {
  font-size: 30px;
}

.honor-list {
  background-color: #067aac;
}

.concerts-con {
  width: 390px;
  background-color: #000000;
  color: #f3f6f6;
}
.reg-button {
  width: 200px;
  background-color: #000000;
  height: 50px;
  margin-right: 1300px;
  margin-top: 30px;
  border-radius: 20px;
  list-style: none;
}

.Sign-btn {
  width: 200px;
  background-color: #000000;
  height: 50px;
  margin-right: 100px;
  border-radius: 20px;
  list-style: none;
}

.reg-btn-reg-now {
  font-size: 30px;
  text-decoration: none;
  color: #ec6403;
}

.reg-btn-reg-now:hover {
  color: #5abde7;
}

.Sign-up {
  text-decoration: none;
  font-family: arial;
  font-size: 20px;
  color: #ec6403;
}

.Sign-up:hover {
  color: #5abde7;
}

.reg-btn-reg-now :hover {
  color: #d5700a;
}

.concerts-p {
  color: white;
}

.pro {
  color: white;
}

.song-name {
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.song-genre {
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.songs-roll {
  background-color: #f3d3a2;

  cursor: pointer;
}

.songs-roll:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(170, 248, 231);
}
.digit:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(245, 201, 7);
}
.song-table {
  margin-left: 320px;
}

.digit {
  color: #ffffff;
  background-color: #09151b;
  font-size: 26px;
}
.with-andrew :hover {
  color: #d5700a;
}

.with-andrew {
  font-size: 24px;
}

.rule-parent {
  background-color: rgb(6, 20, 20);
}
.rule-child {
  color: #fff;
  padding-top: 40px;
  text-align: start;
  margin-left: 7rem;
  font-size: 22px;
  width: 50%;
}

.choir-tip {
  background-image: url(/static/media/whitesea.91640a97.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 200px;
  font-size: 25px;
}

.slider {
  background-color: #000000;
  width: 100% auto;
  overflow: hidden;
  border: 5px solid #000000;
}

.video-slider {
  background-color: #000000;

  width: 100% auto;
  overflow: hidden;
  border: 5px solid #000000;
}

.images {
  display: flex;
  width: 100%;
  -webkit-animation: 20s slider infinite;
          animation: 20s slider infinite;
}

.images img {
  width: 100%;
}

.images input {
  display: none;
}

.liberation-text-box {
  background-color: #faedd1;
  font-size: 1.4rem;
  cursor: pointer;
}

.contact {
  position: relative;
  min-height: 100vh;
  padding: 50px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #010e0d;
  background-size: cover;
}

.contact .content {
  max-width: 800px;
  text-align: center;
}
.contact .content h2 {
  font-size: 36px;
  font-weight: 500;
  color: #fff;
}

.contact .content p {
  font-weight: 300;
  color: #fff;
}
.container-ner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.container-ner .contact-info {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.container-ner .contact-info .box-box {
  position: relative;
  padding: 20px 0;
  display: flex;
}
.container-ner .contact-info .box-box .icons {
  min-width: 60px;
  height: 60px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 22px;
}
.container-ner .contact-info .box-box .text {
  display: flex;
  margin-left: 20px;
  font-size: 18px;
  color: #fff;
  flex-direction: column;
  font-weight: 200;
}

.container-ner .contact-info .box-box .h3 {
  font-weight: 500;
  color: #067aac;
}

.contact-form {
  width: 40%;
  padding: 40px;
  background-color: #fff;
}

.contact-form h2 {
  font-size: 30px;
  color: #110534;
  font-weight: 500;
}

.contact-form .inputbox {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.contact-form .inputbox input,
.contact-form .inputbox textarea {
  font-size: 16px;
  width: 100%;
  padding: 5px 0;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}

.contact-form .inputbox span {
  position: absolute;
  left: 0;
  font-size: 16px;
  padding: 5px 0;
  margin: -20px 0;
  pointer-events: none;
  transition: 0.6s;
  color: #067aac;
}

.contact-form .inputbox input:focus ~ span,
.contact-form .inputbox textarea:focus ~ span {
  color: #eb1102;
  margin-top: -1px;
  font-size: 24px;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
}

.inputbox .submit-mit {
  width: 100px;
  height: 60px;
  border: none;
  background-color: #d2f7f5;
  color: #000000;
  border: none;
  font-size: 20px;
  border-radius: 30px;
  padding: 10px;
  font-size: 19px;
  cursor: pointer;
}
.inputbox .submit-mit:hover {
  background-color: #5eeee2;
  transition: 0.6s;
  color: #000000;
}

.profile {
  min-width: 60%;
  min-width: 80%;
  min-height: 500px;
  background-color: #d5d0c9;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px 30px;
  margin: auto;
}

.profile .profile-left {
  width: 40%;
  min-height: 500px;
  background-color: #08f7e3;
}

.profile .profile-right {
  width: 100%;
  min-height: 500px;
  background-color: #110534;
}



.direclist {
  background-color: #a5d5e9;
}


.tableble {
  background-color: rgb(203, 245, 239);
  }

.tablename:hover {
 background-color: rgb(247, 205, 127);
 transition: 0.3s ease-in-out;
}

.tab {
width: 100%;
}

.propro {
  background-color: #000000;
}


.probox {
  background-color: #060311;
  margin-top: 90px;
  padding: 8px;
}

.poptab { 
  background-color: #cec1c1;
  color: #a4ece6;
}


.propro h2{
  color: orange;
  font-size: 1.9rem;
  padding: 60px;
  margin-left: 2rem;
 }
.proname {
  color: #08f7e3;
  font-size: 1.8rem;
  margin-left: 1.6rem;
} 

.avaimage {
  margin-left: 6.7rem;
  margin-top: -50px;
}

.gendergen {
  background-color: #090222;
  color: #eff8f4;
  font-size: 1.5rem;
}


.form-wrapper {
    background-color: #ff7200;
}


.reg-reg img{
margin-left: 900px;
width: 60%;
margin-top: -840px;
}

.form {
width: 30em;
height:35em;
border: 5px solid #000000; 
background-color: rgb(9, 31, 31);
border-radius: 1.25rem;
margin-left: 48.125rem;
margin-top: 3.75rem;

}

    .user-name {
        width: 380px;
        height: 55px;
        margin-left: -2px;
        text-align: center;
        font-size: 20px;
        margin-top:30px;
        font-family: sans-serif;
        border-right: none;
        border-left: none;
        color: #f8fafc;
        font-size: 23px;
        letter-spacing: 1px;
        margin-top:30px;
        font-family: sans-serif;
        }

.newtshirtlogoo-a {
   
    margin-left: 1400px;
    margin-top: -616px;
}

.user {
  margin-left: -30px;
  font-size: 25px;
}
  .add-name {
        width: 350px;
        height: 55px;
        margin-left: -2px;
        text-align: center;
        font-size: 20px;
        letter-spacing: 1px;
        margin-top:30px;
        font-family: sans-serif;
        background-color: rgb(255, 255, 255);
        background: transparent;
        border-bottom: 1px solid #ff7200;
        border-right: none;
        border-left: none;
        color: #000000;
        font-size: 23px;
        letter-spacing: 1px;
        margin-top:80px;
        font-family: sans-serif;
  }

  .add-form {
    background-color: rgb(9, 31, 31);
    width:550px;
    height:710px;
    margin-left: 770px;
    margin-top: 40px;
  }

   .add-form input{
    color: #fff;
  }

  .add-form h1 {
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .summit-btn {
    cursor: pointer;
    width: 110px;
    height: 50px;
    margin-top: -6px;
    background-color: rgb(0, 0, 0);
    color: #fff;
  }

    .summit-btn:hover {
    background-color: rgb(252, 250, 250);
    color: #000000;
  }

  .back-btn {
    cursor: pointer;
    width: 110px;
    height: 50px;
    margin-left: 18px;
    list-style-type: none;
    background-color: aqua;
    
  }

   .back-btn:hover {
   background-color: #cf0000;
   color: #fff;
  }
        
        .pass-word {
        width: 380px;
        height: 55px;
        font-size: 20px;
        text-align:center;
        margin-left: -2px;
        border-right: none;
        border-left: none;
        font-size: 25px;
        margin-top:30px;
        font-family: sans-serif;
        }
.log-reg {
 background-color: #000000;
    place-items: center;
    display: grid;
    height: 100%;
}

.reg-quotation {
    background-color: #1c1c1c;
    color: #7e7c7c;
    text-align: start;
}
.log-log {
    background-color: rgb(28, 101, 236);
    
    place-items: center;
    display: grid;
    height: 100%;
}
.back {
    text-decoration: none;
    font-size: 20px;
    color: #f8fafc;
}

.back:hover {
    text-decoration: underline;
}
.container {
max-width: 50.5rem;
padding: 0 1.25rem;
margin: 10.625rem auto;
}


.wrapp {
  width: 100%; 
  display: flex; 
  margin-top: -140px;
  background-color: #0f0f0f;
  border-radius: 10px;
}
.wrapp .title {
 line-height: 2rem;
 font-size: 25px;
 color: #fff;
 }

.wrapp form {
 padding: 30px 20px 25px 20px;
}
.wrapp form .row-row{
    height: 2.825rem;
    margin: 20px;
    margin-bottom: 15px;
    position: relative;
   }
   form .row-row input:focus {
       border: #db5000;
       box-shadow: inset 0px 0px 2px 2px rgba(241, 159, 6, 0.25);
   }
   .wrapp form .row-row input{
   height: 100%;
   width: 100%;
   outline: none;
   font-size: 23px;
   padding-left: 60px;
   border-radius: 5px;
   }

     .row-row .sublog{
    border: none;
    padding-right: 50px;
    background-color: rgb(32, 34, 36);
    color: #fff;
    cursor: pointer;
   }
   .row-row .sublog:hover{
   border: none;
   background-color: #000000;
   color: #ff7300;
   transition: 0.4s ease;
   }
   
   .wrapp form .pass{
    padding-top: 20px;
    margin: 13px;
    text-decoration: none;
    font-size: 24px;
   }
   .wrapp form .pass a{
    text-decoration: none;
   }
   .wrapp form .pass a:hover {
       text-decoration: underline;
   }
  
  

   .fauser{
    position: absolute;
    width: 47px;
    height: 100%;
    color: #fff;
    font-size: 60px;
    background: #000000;
    border: 1px solid #fdfcfb;
    display: flex;
    align-items: center;
    justify-content: center;
   }
        
.glojes-account{
 background-color: white;
 border-radius: 40px;   
 color: #db5000;
 padding: 10px;

}
.btn-form-log {
    width:200px;
    height:40px;
    background-color: #08f7e3;
    border: none;
    margin-top: 20px;
    font-size: 25px;
    text-transform: uppercase;
    border-radius: 10px;
    margin-left: 5px;
    cursor: pointer;
    color: #000000;
    transition: 0.4s ease;
}
.btn-form-log:hover {
    background-color: rgb(0, 0, 0);
    
    color: #ee5f00;
}
        .btn-form {
            width:200px;
            height:40px;
            background-color: #08f7e3;
            border: none;
            margin-top: 1px;
            font-size: 25px;
            text-transform: uppercase;
            border-radius: 10px;
            
            cursor: pointer;
            color: #000000;
            transition: 0.4s ease;
            }

.btn-form:hover {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
    background-color: #db5000;
    color: rgb(0, 0, 0);
    }
       
          
        
    .reg-form {
       padding: 20px;
       margin-left: 45px;
      

    }

.gender-category {
    margin-left: 10px;
}



.gender-category label{
padding: 20px;
font-size: 25px;
color: #ffffff;
}
.gender-category label, .gender-category input {
cursor: pointer;
}



        .login {

            font-size: medium;
           

        }

        .form .link{
            font-family: arial, helvetica, sans-serif;
            padding-top: 20px;
            text-align: center;
            }

        .link-button {
           
            margin-left: 15px;
            margin-top: 20px;
            margin-right: 34px;
            list-style: none;
           

        }

    .link {
color:#fffdfd;
font-family: arial, helvetica, sans-serif;
text-align: center;
font-size: 23px;
margin-left: 20px;


    }


.with-andrew {
color: rgb(0, 147, 232);
text-decoration: none;
display: flex;
justify-content: center;
align-items: center;
border-radius: 20px;
 
}

.with-andrew:hover {
    text-decoration: underline;
}



.registration-form {
       background-color: rgb(7, 12, 12);
       width:30%;
       height: 140%;
       font-family: Verdana, Geneva, Tahoma, sans-serif;
       justify-content: space-between;
       align-items: center;
       margin-left: 698px;
    
        }

.registration-form h2 {
    color:#fffefe;
    font-family: Microsoft YaHei UI Light;
    font-size: 30px;
    
}

.homeform{
    margin-top: -3.125rem;
   
}

.rules-link-link {
    color: rgb(252, 247, 247);
    font-size: 30px;
    
}
.rules-link-link:hover {
    color: #da5802;
}

.alert p {
    color:#fdfafa;
    font-size: 23px;;
}

.image-display-before {
   margin-top: 8px;
   cursor: pointer;
   width: 130px;
   height: 110px;
   border-radius: 100%;
}

.image-display-after {
    margin-top: 8px;
    cursor: pointer;
    width: 130px;
    height: 110px;
    border-radius: 100%;
 }

.image-upload-btn {
    width: 170px;
    height: 40px;
    font-size: 25px;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgb(255, 145, 1);
     }
    
     .image-upload-btn:hover {
        background-color: #262121;
        color: aqua;
        margin-right: -19px;
     }


     .box-in {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        background-color: #000000;
    }
    
    .box {
      position: relative;
      width: 490px;
      height: 520px;
      background-color: #181717;
      margin: 10.625rem auto;
      border-radius: 8px;
    }
    
    .box form{
      align-items: center;
      inset: 4px;
      background-color: orangered;
      padding: 50px 40px;
      border-radius: 8px;
      z-index: 2;
      display: flex;
      flex-direction: column;
    }
    
    .box form h1{
    color: #000000;
    }
    
    .box form .inputbox{
      position: relative;
      width: 300px;
      margin-top: 35px;
    }
    
    .box form .inputbox input{
      position: relative;
      width: 100%;
      padding: 20px 10px 10px;
      background: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      color: #23242a;
      font-size: 1.3em;
      letter-spacing: 0.05em;
      transition: 0.5s;
      z-index: 10;
    }
    
    .box form .inputbox span
    {
      position: absolute;
      left: 0;
      padding: 20px 0px 10px;
      pointer-events: none;
      color: #000000;
      font-size: 1.2em;
      letter-spacing: 0.05em;
      transition: 0.5s;
    }
    
    .box form .inputbox input:valid ~ span,
    .box form .inputbox input:focus ~ span
    {
     color: #fff;
     margin-top: -0.45rem;
     font-size: 1.3em;
     -webkit-transform: translateY(-34px);
             transform: translateY(-34px);
    }
    
    .box form .inputbox i {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: #fff;
      border-radius: 4px;
      overflow: hidden;
      transition: 0.5s;
      pointer-events: none;
    }
    
    .box form .inputbox input:valid ~ i,
    .box form .inputbox input:focus ~ i
    {
      height: 44px;
    }
    
    .box form .links {
      display: flex;
      grid-column-gap: 100px;
      -webkit-column-gap: 100px;
              column-gap: 100px;
      font-size: 1.2em;
    }
    
    .box form .links a{
      margin: 10px 0;
      font: 0.75em;
      color: #000000;
      text-decoration: none;
    }
    .box form .links a:hover{
     color: #fff;
     text-decoration: underline;
    }
    
    .box form input[type="submit"]
    {
      border: none;
      outline: none;
      padding: 9px 25px;
      background-color: #000000;
      color: #fff;
      cursor: pointer;
      font-size: 1.4em;
      border-radius: 4px;
      font-weight: 600;
      width: 150px;
      margin-top: 10px;
    }
    .box form input[type="submit"]:hover
    {
     background-color: #fdfdfd;
     color: #000000;
     -webkit-transform: scale(1.15);
             transform: scale(1.15);
     transition: 0.3s ease;
    }
    
    .box form input[type="submit"]:active
    {
     opacity: 0.8;
    }







    .promo-too {
      min-height: 80vh;
      width: 100%;
      background-position: center;
      background-size: cover;
      background-image: url(/static/media/crowww.91a0cb4f.jpg);
      background-repeat: no-repeat;
    }
    
  
@media (max-width: 991px) {
  .videorow-textbox {
    width: 60%;
  }

  .ment h1 {
    font-size: 50px;
  
  }

  .logstart h1{
    color: orangered;
    font-size: 3rem;
    font-family: Arial, Helvetica, sans-serif;
   }

   .dorcus-row .right .content {
  padding-left: 0;
 }
 .Sati-row .right .content {
  padding-left: 0;
 }
 .Kasselie-row .right .content {
  padding-left: 0;
 }
 .elkana-row .right .content {
  padding-left: 0;
 }
 .peter-row .right .content {
  padding-left: 0;
 }
 .glo-concert-btn {
  padding-left: 0;  
}

}
@media (max-width: 768px) {

 

  .logstart h1{
    color: orangered;
    font-size: 250%;
    font-family: Arial, Helvetica, sans-serif;
   }
   
   .logstart p{
    color: #fff;
    font-size: 1.4rem;
    margin-top: -5.3em;
   font-family: Arial, Helvetica, sans-serif;
   }

  .dorcus-row {
    width: 90%;
    margin-left: 2.3rem;
    grid-template-columns: 1fr;
   
}

.left-dor img{
  width: 70%;
  height: 100%;
  object-fit: cover;
  margin-left: 0.125rem;
  border: 5px solid #000000;  
}
.left-Sati img {
  width: 70%;
  height: 100%;
  object-fit: cover;
  margin-left: 0.125rem;
  border: 5px solid #000000;  
}

.summit-row {
  width: 80%;
  display: grid;
  margin-top: 11rem;
  margin-left: 2rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem 1.8rem;
 }




.glo-concert-btn {
  margin-left: 54rem;

}

 .homeform{
  margin-top: -570px;
  margin-left: 560px;
 }



}

@media only screen and  (max-width:320px) {
   .concert-content p{
  padding-top: 70px;
  font-size: 25px;
  width: 85%;
  margin-left: 50px;
}
}

@media (max-width: 2046px) {

  .major-rules {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
}



@media (max-width: 768px) {

.wrapper-crownn {
  width: 90%;
  grid-template-columns: 1fr;
}
.wrapper-crownn p{
  font-size: 1.5rem;
  line-height: 1.6rem;
  padding-bottom: 1rem;
}




  .summit-row {
    width: 90%;
    margin-left: 2.3rem;
    grid-template-columns: 1fr;
 }
 .concert-content p{
  padding-top: 70px;
  font-size: 25px;
  width: 85%;
  margin-left: 50px;
}

}

@media (max-width: 769) {
  .wrapper-crownn {
    margin-left: 20rem;
    }
}

@media (max-width: 1024px) {
  nav a {
    font-size: 8px;
   }
  


  .choir-tip {
    background-image: url(/static/media/whitesea.91640a97.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 25px;
    margin-top: 1000px;
    }

  

      .reg-button {
        width: 100px;
        background-color: #000000;
        height: 40px;
        margin-left:250px;
        top: 250px;
        left:8px;
        border-radius: 20px;
        list-style: none;
      }


      .reg-btn-reg-now {
        font-size: 20px;
        text-decoration: none;
        color: #ec6403;
       
     }
    
      .Sign-btn {
        width: 100px;
        background-color: #000000;
        height: 40px;
        margin-left: -450px;
        top: 250px;
        left: 550px;
        border-radius: 20px;
        list-style: none;
      }
    
  

    .graceinvasion-box {
      width: 50%;
      margin-left: -1190px;
      justify-content: center;
      align-items: center;
      top:1280px;
      left:1376px;
    }

    .crownn-box {
      width: 50%;
      justify-content: center;
      align-items: center;
      margin-left: -1250px;
      top: 1900px;
      left: 1434px;
    }

 

  .reg-box {
    margin-top: 2500px;
    margin-left: -509px;
  }

  .text-box-box-box p {
   font-size: 18px;
  
  }

 


}


@media (max-width: 1024px)
{

 .form {
margin-top: 600px;
margin-left: -476px;

}
  

.rules-row {
  padding-top: 10px;
  width: 90%;
  display: flex;
  justify-content: center;
  grid-column-gap: 140px;
  -webkit-column-gap: 140px;
          column-gap: 140px;
  padding-top: 1.34%;
  margin: auto;
}
   
.wrapper {
  width: 90%;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: -600px;
}

.wrapper-crownn {
width: 90%;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.wrapper-graceinvasion {
width: 90%;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.wrapper-joh {
 width: 90%;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.wrapper h1 {
  font-size: 42px;
  }

  .schedule-wrapper {
  background-color: #000000;
  margin-top: 2390px;
}

  .wrapper-concert {
  background-color: #e1e8ec;
  width: 600px;

  margin-left:250px;
  top: 420px;
  left:-150px;
  border: 5px solid #fffefe;
}

.wrapper-side-two-concert {
  background-color:  #e1e8ec;
  width: 600px;
 
  margin-left: -660px;
  margin-top: 1060px;
  border: 5px solid #fffefe;
}

  .christinaa2 {
    width: 300px; 
    height: 378px;
    margin-left: 40px;
    border: 5px solid #2a241e; 
    border-radius: 30px;
    margin-top: 40px;
   
    }

    .johnetr {
      max-width: 600px; 
      height: 400px;
      float: inline-start; 
      border: 5px solid #2a241e; 
      border-top-left-radius: 90px;
      border-bottom-right-radius: 90px;
      margin-right: 20px;
      margin-top: 40px;
      
  }


.top-glojes h1 {
  font-size: 42px;
}

.choir-tip {
 margin-top: 100px;
}
.wrapper-crownn {
  margin-top: 50px;
}
.wrapper-graceinvasion {
  margin-top: 50px;
}

}

@media only screen and (max-width: 768px){
  header .nav-btn {
      visibility: visible;
      background-color: #000000;
      opacity: 1;
  }
 
  .signupup {
    width: 40%;
  }
  
  header nav {
      position: fixed;
      top: 0;
      left: 0;
      padding: 40px;
      height: 50%;
      width:  100%;
      display: flex;
      flex-direction: column;
      margin-right: 60px;
      align-items: center;
      justify-content: center;
      background-color: orangered;
      -webkit-transform: translateY(-100vh);
              transform: translateY(-100vh);
      }

      .header {
       color: #ee7621;

      }


     header .responsive_nav{
          -webkit-transform: none;
                  transform: none;
          
      }

      nav .nav-close-btn {
               position: absolute;
               top: 1rem;
               right: 2rem;
              
       }

       nav a {
        font-size: 18px;
       }

    }


    @media (max-width: 991px)
    {
      .contact {
        padding: 50px;
      }
      .container-ner {
       flex-direction: column;
      }
      .container-ner .contact-info{
        margin-bottom: 40px;
       }
       .container-ner .contact-info,
       .contact-form
       {
        width: 100%;
       }
    }
    
    @media (max-width: 1119px) {
      nav a {
        list-style:none;
        margin-left: 18px;
        margin-top: 20px;
        font-size: 18px;
        }
    }
    @media (max-width: 1171px) {
      nav a {
        list-style:none;
       
        font-size: 18px;
        }
    }

    @media (max-width: 600px) { 
      .announce-row {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .con-row {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .tour-row {
        display: flex;
        align-items: center;
        flex-direction: column;
        }

      .directors-row {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .rules-row {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      

      .signupup {
        width: 40%;
      }

      
}
@media (max-width: 1334px) {
  .announce-row {
    display: flex;
    flex-direction: column;
  }

  .dirlink {
    text-decoration: none;
    font-size: 15px;
  }




  .aboutabout {
    margin-left: -1rem;
    font-size: 13px;
     text-align: start;
   }

  .guidelines-text-box {
    width: 90%;
    margin: auto;
    text-align: start;
    font-family: sans-serif;
    margin-top: 2rem;
    font-size: 15px;
    background-color: white;
    color: rgb(0, 0, 0);
  }

  .rulesrow-text-box {
    text-align: start;
    font-family: sans-serif;
    margin-top: 2rem;
    font-size: 16px;
    background-color: white;
    color: rgb(0, 0, 0);
  }
 
  
  .tour-row {
    display: flex;
    flex-direction: column;
   padding: 20px;
    width: 80%;
   
    }

  .con-row {
    display: flex;
    flex-direction: column;
  } 
  .rowling {
   width: 90%;
   grid-template-columns: repeat(2, 1fr);
  }
  .additionright{
    width: 100%;
    
   }
  .videorow {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

.major-rules {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}


  .summitrowling {
    width: 90%;
    grid-template-columns: repeat(2, 1fr);
   }
  .directors-row {
    display: flex;
    flex-direction: column;
  }

  .rules-row {
    display: flex;
    flex-direction: column;
  }

  .conpops {
    padding: 30px;
  }

  .announce-two {
    padding: 30px;
  }
  .announce-two img {
    width: 100%;
    height: 100%; 
}
.conpops img {
  width: 80%;
  height: 40%; 
}
.directors-two img {
    width: 20%;
    height: 10%; 
    border-radius: 100px;
}



.guidelines{
  width: 100%;
  margin: auto;       
} 


}


    @media (min-width: 601px) {
      .announce-row {
        width: 96%;
        align-items: center;
        }

       
        

        .con-row {
          width: 70%;
          align-items: center;
          }

    }
    @media (max-width: 991px) {
      .rowling .rowlingright .content{
      padding: 0;
      }
      .summitrowling .summitrowlingright .content{
        padding: 0;
        }
        

    }
    @media (max-width: 768px) { 

    
      .rowling {
        width: 90%;
        grid-template-columns: 1fr;
       
        }

        .guidelines-text-box {
          width: 90%;
          margin: auto;
          text-align: start;
          font-family: sans-serif;
          margin-top: 2rem;
          font-size: 15px;
          background-color: white;
          color: rgb(0, 0, 0);
        }

        .summitrowling {
          width: 90%;
          grid-template-columns: 1fr;
         
          }

          .summitrowlingleft img {
            margin-left: -2.3rem;
        }
    }

    @media (min-width: 767px) { 
      .signupup {
        width: 50%;
        }

        .promo-too {
          min-height: 80vh;
          width: 100%;
        }
    }
    @media (min-width: 1292px) { 
      .signupup {
        width: 20%;
        
       
        }
    }
    @media (min-width: 349px) { 
      .hometext-box p{
        width: 90%;
        }

        .promo-too {
          min-height: 80vh;
          width: 100%;
        }
    }

    @media (min-width: 704px) { 
     
        .promo-too {
          min-height: 80vh;
          width: 100%;
        }
    }

    @media (min-width: 1326px) { 
      .hometext-box p{
        width: 50%;
        }

       


        .guidelines-text-box {
          width: 90%;
          margin: auto;
          text-align: start;
          font-family: sans-serif;
          margin-top: 2rem;
          font-size: 1.34rem;
          background-color: white;
          color: rgb(0, 0, 0);
        }
    }

    @media (max-width: 726px) { 
      .wrapp {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .one-one-text-box h1 {
        font-size: 1.5rem;
      }
      
      .one-one-text-box p {
        font-size: 15px;
      }
      
      .two-text-box h1 {
        font-size: 1.5rem;
      }
      .three-text-box h1 {
        font-size: 1.5rem;
      }

      .dirlink {
        text-decoration: none;
        font-size: 15px;
      }

      .ceos h4 {
        font-size: 20px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      
        margin-top: 2rem;
        
        background-color: white;
        color: rgb(0, 0, 0);
      }

      .tour-row {
        padding-top: 10px;
        width: 98%;
        display: flex;
        justify-content: center;
        grid-column-gap: 140px;
        -webkit-column-gap: 140px;
                column-gap: 140px;
        padding-top: 1.34%;
        margin: auto;
      }


      .aboutabout {
        margin-left: 3rem;
        font-size: 13px;
         text-align: start;
       }

    } 
    @media (max-width: 607px) { 

     


      .wrapp {
        width: 90%; 
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .aboutabout {
       font-size: 13px;
        text-align: start;
      }

      .about {
        width: 90%;
        align-items: center;
        padding: 2rem;
        margin: auto;
      }

      .songs-concert-content p {
        padding-top: 70px;
        font-size: 14px;
        width: 80%;
        margin-left: 50px;
      }

    }

    @media (max-width: 695px) {


      .rules-row {
        padding-top: 10px;
        width: 90%;
        display: flex;
        justify-content: center;
        grid-column-gap: 140px;
        -webkit-column-gap: 140px;
                column-gap: 140px;
        padding-top: 1.34%;
        margin: auto;
      }
      
      .guidelines-text-box {
        width: 90%;
        margin: auto;
        text-align: start;
        font-family: sans-serif;
        margin-top: 2rem;
        font-size: 15px;
        background-color: white;
        color: rgb(0, 0, 0);
      }

     .one-rule {
      width: 100%;
      margin-left: -43px;
      
    }

    .rulesrow-text-box {
      text-align: start;
      font-family: sans-serif;
      margin-top: 2rem;
      font-size: 13px;
      background-color: white;
      color: rgb(0, 0, 0);
    }
   
    .rules-icon {
      min-width: 20px;
      display: flex;
      height: 20px;
      background-color: #fa9420;
      color: rgb(0, 0, 0);
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 9px;
    }

 .one-rule p{
  font-size: 10px;
  width: 100%;
}

.promotext-box p{
  color: white;
  font-size: 10px;
  width: 100%;
  text-align: center; 
  margin: auto;
  font-family: Myanmar Text;
}

.hometext-box p{
  color: white;
  font-size: 22px;
  width: 95%;
  text-align: center; 
  margin: auto;
  font-family: Myanmar Text;
}

.hometext-box {
  padding-top: 30px;
}
    }

    @media (max-width: 966px) { 

    .hometext-box {
       padding-top: 30px;
     }
         }

 @media (max-width: 1706px) {  


  .adverth {
    margin: 8px;
    width: 95%;
    height: 40%; 
}


  .summitrowling .summitrowlingright .summitcontent p {
    font-size: 13px;
    text-align: start;
   
  }

  .promotext-box h1{
    color: white;
    font-size: 10px;
    padding: 5px;
    margin: auto;
    font-family: Futura Bk BT;
  }

          .hometext-box h1{
            width: 40%;
            color: white;
            font-size: 2.15rem;
            padding: -90px;
            margin: auto;
            font-family: Futura Bk BT;
          }

          .promo-too {
            min-height: 10vh;
            width: 100%;
          }

          .home-too {
            min-height: 60vh;
            width: 100%;
            background-position: center;
            background-size: cover;
            
            background-repeat: no-repeat; 
          
           
          }
          .hometext-box {
            padding-top: 10px;
          }

         

  }

  @media (max-width: 600px) {  

    .adverth {
      margin: 8px;
      width: 95%;
      height: 40%; 
  }

    

    .hometext-box p{
      color: white;
      font-size: 22px;
      width: 100%;
      text-align: center; 
      margin: auto;
      font-family: Myanmar Text;
    }

    .hometext-box h1{
      width: 40%;
      color: white;
      font-size: 2.15rem;
      padding: -90px;
      margin: auto;
      font-family: Futura Bk BT;
    }

    .home-too {
      min-height: 30vh;
      width: 100%;
    }

    .promo-too {
      min-height: 30vh;
      width: 100%;
    }

    .hometext-box {
      padding-top: 10px;
    }

    .signupup {
      width: 30%;
      height: 40px;
      margin: auto;
      font-size: 16px;
      background-color: #000000;
      cursor: pointer;
      color: #08f7e3;
      border-radius: 10px; 
    }
  }

  @media (max-width: 400px) { 

   

    .major-rules {
      margin-left: 58px;
    }

    .con {
      background-color: #060311;
      color: #08f7e3;
      margin: auto;
      font-size: 12px;
    }

    .rls {
      background-color: #060311;
      color: #08f7e3;
      margin: auto;
      font-size: 12px;
    }

    .rule-textbox {
      width: 700px;
      justify-content: center;
      padding-left: -30px;
      text-align: start;
      display: flex;
      margin-left: -34px;
      background-color: #0c0427;
      color: white;
      font-size: 25px;
    }

    .rule-textbox:hover {
      background-color: #fffbfb;
      color: rgb(0, 0, 0);
      cursor: pointer;
      -webkit-transform: scale(1.0);
              transform: scale(1.0);
      transition: 0.1s ease;
    }

    .home-too {
      height: 8vh;
      width: 100%;
    }

    .adverth {
      margin: 8px;
      width: 95%;
      height: 40%; 
  }

    .hometext-box p {
      color: white;
      font-size: 13px;
      width: 100%;
      font-family: Myanmar Text;
    }
  }


  @media (max-width: 644px) {  

    .con {
      background-color: #060311;
      color: #08f7e3;
      margin: auto;
      font-size: 12px;
    }

    .rls {
      background-color: #060311;
      color: #08f7e3;
      margin: auto;
      font-size: 12px;
    }


    .logstart h1{
      color: orangered;
      font-size: 150%;
      font-family: Arial, Helvetica, sans-serif;
     }
     
     .logstart p{
      color: #fff;
      font-size: 1.4rem;
      margin-top: -5.3em;
     font-family: Arial, Helvetica, sans-serif; 
     }
    
  }

  @media (min-width: 300px) {  

    .signlog {
      margin: 20px;
      font-size: 1rem;
      width: 30%;
      height: 40px;
     
      }

  }

  @media (min-width: 948px) {  

    .signlog {
      margin: 20px;
      font-size: 1rem;
      width: 20%;
      height: 40px;
     
      }

  }

  @media (min-width: 1384px) {  

    .signlog {
      margin: 20px;
      font-size: 1rem;
      width: 10%;
      height: 40px;
     
      }

  }

  @media (min-width: 898px) { 
    
 
   

  }

  @media (max-width: 894px) {  

    .con {
      background-color: #060311;
      color: #08f7e3;
      margin: auto;
      font-size: 12px;
    }

    .rls {
      background-color: #060311;
      color: #08f7e3;
      margin: auto;
      font-size: 12px;
    }


    .additionright .additioncontent p{
      font-family: sans-serif;
      text-align: start;
      font-size: 22px;
      padding: 30px;
      width: 80%;
      margin: auto;
    }
    
  }

  @media (max-width: 1782px) {  
    .scrollbar {
      background-color: #edf3f3;
      height: 300px;
      width: 80%;
      margin: auto;
      font-family: Arial, Helvetica, sans-serif;
      overflow: auto;
    }
    
  }
  
  @media (max-width: 1297px) {

    .about{
      width: 90%;
      margin: auto;
     }
  }
  
  @media (max-width: 682px) {

    .about{
      width: 90%;
      margin: -2px

     }
   
  }
  
  @media (max-width: 1417px) {

    .chrisprofile{
      width: 90%;
      margin: auto;
     }
     
     .andrewprofile{
      width: 90%;
      margin: auto;
     }
     
     .peterprofile{
      width: 90%;
      margin: auto;
     }

     .elknaprofile{
      width: 90%;
      margin: auto;
     }
  }

  
@media (max-width: 490px) {

  .ment h1 {
    font-size: 30px;
  
  }

  .summitrowling .summitrowlingright .summitcontent p {
    font-size: 13px;
    text-align: start;
   
  }


}

@media (max-width: 767px) {

  .tab {
    width: 100%;
    }

    .hometext-box p {
      color: white;
      font-size: 13px;
      width: 100%;
      font-family: Myanmar Text;
    }

    .hometext-box h1 {
      width: 60%;
      color: white;
      font-size: 1.75rem;
      padding: -90px;
      margin: auto;
      font-family: Futura Bk BT;
    }

    
}


@media (max-width: 760px) { 

  .probox {
    
    padding: 3px;
  } 
  

}

@media (max-width: 648px) { 

  .probox {
    max-width: 89%;
    padding: 2px
  }  
}



@media (max-width: 1846px) { 

  .directors img {
    width: 25%;
}

  .chrisprofile p{
    width: 95%;
    margin: auto;
    text-align: start;
    font-size: 14px;
    color: rgb(0, 0, 0);
  }

  .con-text-box {
    text-align: start;
    font-family: sans-serif;
    margin-top: 2rem;
    font-size: 14px;
    background-color: white;
    color: rgb(0, 0, 0);
  }

  


  .propro h2{
    font-size: 1.9rem;
    padding: 60px;
    margin-left: 9px;
   } 

   .avaimage {
    margin-left: 5.1rem;
    margin-top: -50px;
  }
    
  .proname {
    color: #08f7e3;
    font-size: 1.4rem;
    margin-left: 38px;
  } 
}

@media (max-width: 1590px) { 

.proname {
    color: #08f7e3;
    font-size: 1rem;    
    margin-left: 38px;
  } 
}

@media (max-width: 1610px) { 

  .avaimage {
    margin-left: 12px;
    margin-top: -50px;
  }

  .proname {
      color: #08f7e3;
      font-size: 12px;    
      margin-left: 8px;
    } 

    .propro h2{
      font-size: 15px;
      padding: 60px;
      margin-left: -50px;
     } 
     .email {
      font-size: 15px;
     
     }
  }

  
  @media (min-width: 767px) {
    .hometext-box {
      background-image: linear-gradient(rgb(21, 2, 39), rgba(0, 0, 0, 0.815));
      padding-top: 10px;
    }
   }
  
@media (max-width: 886px) { 

  .hometext-box {
    background-color: transparent;
    height: 60vh;
    padding-top: 10px;
  }

  .text p {
    font-size: 15px;
  }

  .promo-too {
    border: 6px solid rgb(212, 99, 0);
    margin-top: 20px;
  }

  .rowling .rowlingleft img{ 
    padding: 20px; 
   margin: auto;
    margin-top: -60px;
    width: 100%;
    height: 100%;
    
  }

  .avaimage {
    margin-left: 12px;
    margin-top: -50px;
  }

  .ment h1 {
    font-size: 34px;
    padding: 30px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  .ment p {
    font-size: 12px;
  }

  .homemar {
    background-image: linear-gradient(rgba(26, 1, 1, 0.7), rgba(18, 1, 34, 0.911));
    margin-top: -10px;
   }

   .hometext-box p {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    color: white;
    font-size: 20px;
    width: 50%;
    text-align: center;
    margin-top: 110px;
    font-family: Myanmar Text;
  }

  .hometext-box {
    background-color: transparent;
    padding-top: 10px;
  }

  .hometext-box p {
    color: white;
    font-size: 13px;
    width: 100%;
    
   
    font-family: Myanmar Text;
  }

  .hometext-box h1 {
    width: 60%;
    color: white;
    font-size: 1.75rem;
    padding: -90px;
    margin: auto;
    font-family: Futura Bk BT;
  }


  .proname {
      color: hsl(175, 94%, 50%);
      font-size: 18px;    
      margin-left: 8px;
    } 

    .propro h2{
      font-size: 18px;  
      padding: 60px;
      margin-left: -50px;
     } 
     .email {
      font-size: 19px;
     
     }

     .probox {
      background-color: #060311;
      margin-top: 40px;
      padding: 20px;
      margin-left: 2px;
      max-width: 100%;
    }

    .safe img {
      border: 2px solid orangered; 
      width: 60%;
      margin: auto;
      margin-top: 4rem;
  }

  }

  @media (max-width: 886px) { 

    .promo-too {
      border: 6px solid rgb(212, 99, 0);
      margin-top: 20px;
    }

    .avaimage {
      margin-left: 12px;
      margin-top: -50px;
    }

    .con-row {
      padding-top: 10px;
      width: 95%;
      display: flex;
      justify-content: center;
      grid-column-gap: 140px;
      -webkit-column-gap: 140px;
              column-gap: 140px;
      padding-top: 1.34%;
      margin: auto;
    }
  
    .proname {
        color: hsl(175, 94%, 50%);
        font-size: 18px;    
        margin-left: 8px;
      } 
  
      .propro h2{
        font-size: 18px;  
        padding: 60px;
        margin-left: -50px;
       } 
       .email {
        font-size: 19px;
       
       }
  
       .probox {
        background-color: #060311;
        margin-top: 40px;
        padding: 20px;
        margin-left: 2px;
        max-width: 100%;
      }
  
      .safe img {
        border: 2px solid orangered; 
        width: 80%;
        margin: auto;
        margin-top: 4rem;
    }
  
    }

    @media (max-width: 886px) {

      .promo-too {
        border: 6px solid rgb(212, 99, 0);
        margin-top: 20px;
      }

      .hometext-box p {
        background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
        color: white;
        font-size: 15px;
        width: 95%;
        text-align: center;
        margin-top: 230px;
        font-family: Myanmar Text;
      }


      .directors-one {
        background-color: #ffffff;
        width: 100%;
      }

      .directors img {
        width: 45%;
    }
    
      .chrisprofile p{
        width: 95%;
        margin: auto;
        text-align: start;
        font-size: 14px;
        color: rgb(0, 0, 0);
      }

      .con-text-box {
        text-align: start;
        font-family: sans-serif;
        margin-top: 2rem;
        font-size: 14px;
        background-color: white;
        color: rgb(0, 0, 0);
      }

    }

  @media (max-width: 604px) {

    .promo-too {
      border: 6px solid rgb(212, 99, 0);
      margin-top: 20px;
    }


    .hometext-box p {
      background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
      color: white;
      font-size: 20px;
      width: 50%;
      text-align: center;
      margin-top: 230px;
      font-family: Myanmar Text;
    }

   

    .container-ner .contact-info .box-box .text {
      display: flex;
      margin-left: 20px;
      font-size: 13px;
      color: #fff;
      flex-direction: column;
      font-weight: 200;
    }


    .container-ner .contact-info {
      width: 30%;
      margin-left: -12rem;
      display: flex;
      flex-direction: column;
    }

    .contact-form .inputbox input:focus ~ span,
    .contact-form .inputbox textarea:focus ~ span {
      color: #eb1102;
      margin-top: -1px;
      font-size: 13px;
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    .contact-form .inputbox span {
      position: absolute;
      left: 0;
      font-size: 13px;
      padding: 5px 0;
      margin: -20px 0;
      pointer-events: none;
      transition: 0.6s;
      color: #067aac;
    }


    .hometext-box p {
      color: white;
      font-size: 13px;
      width: 100%;
      
     
      font-family: Myanmar Text;
    }

    .hometext-box h1 {
      width: 60%;
      color: white;
      font-size: 1.75rem;
      padding: -90px;
      margin: auto;
      font-family: Futura Bk BT;
    }

    .rowling .rowlingleft img{ 
      padding: 20px; 
     margin: auto;
      margin-top: -60px;
      width: 100%;
      height: 100%;
      
    }

    .con-text-box {
      text-align: start;
      font-family: sans-serif;
      margin-top: 2rem;
      font-size: 14px;
      background-color: white;
      color: rgb(0, 0, 0);
    }

    .additionright .additioncontent p {
      font-family: sans-serif;
      text-align: start;
      font-size: 22px;
      padding: 30px;
      width: 90%;
      margin: auto;
    }
    .con-row {
      padding-top: 10px;
      width: 95%;
      display: flex;
      justify-content: center;
      grid-column-gap: 140px;
      -webkit-column-gap: 140px;
              column-gap: 140px;
      padding-top: 1.34%;
      margin: auto;
    }

    .announce-row {
      width: 96%;
      align-items: center;
      }


   .announce-two img {
    max-width: 100%; 
}

.announce-one {
 width: 100%;
}

.announce-text-box {
  width: 100%;
  text-align: start;
  margin-top: 2rem;
  font-size: 14px;
  background-color: white;
  color: rgb(0, 0, 0);
}

.rowling .rowlingright .content p {
  font-size: 13px;
  text-align: start;
  
}

.rowling .rowlingright .content  {
margin-top: -10rem;
}
}

@media (max-width: 762px) {


 



  .rowling .rowlingright .content  {
    margin-top: -10rem;
    font-size: 13px;
    }
}

@media (max-width: 607px) { 

  .promo-too {
    border: 6px solid rgb(212, 99, 0);
    margin-top: 20px;
  }

  .directors-one {
    width: 450px;
    margin-left: -25px;
  }

  .directors img {
    width: 45%;
}

  .chrisprofile p{
    width: 95%;
    margin: auto;
    text-align: start;
    font-size: 14px;
    color: rgb(0, 0, 0);
  }

  .back {
    text-decoration: none;
    font-size: 14px;
    color: #f8fafc;
}

.promo-too {
  min-height: 20vh;
  width: 100%;
}

  .gender-category label{
    padding: 20px;
    font-size: 15px;
    color: #ffffff;
    }

  .wrapp .title {
    line-height: 2rem;
   font-size: 15px;
    color: #fff;
    }

    .wrapp form .row-row input{
      height: 100%;
      width: 100%;
      outline: none;
      font-size: 15px;
      padding-left: 60px;
      border-radius: 5px;
      }

  .wrapp {
    width: 90%; 
    display: flex; 
    margin-top: -140px;
    background-color: #0f0f0f;
    border-radius: 10px;
  }

  .box {
    position: relative;
    width: 380px;
    height: 520px;
    background-color: #181717;
    margin: 10.625rem auto;
    border-radius: 8px;
  }

  .box form h1{
    color: #000000;
    font-size: 23px;
    }

    .box form .links {
      display: flex;
      grid-column-gap: 100px;
      -webkit-column-gap: 100px;
              column-gap: 100px;
      font-size: 15px;
    }

    .box form input[type="submit"]
    {
      border: none;
      outline: none;
      padding: 9px 25px;
      background-color: #000000;
      color: #fff;
      cursor: pointer;
      font-size: 20px;
      border-radius: 4px;
      font-weight: 300;
      width: 150px;
      margin-top: 10px;
    }

    .box form .inputbox input{
      position: relative;
      width: 100%;
      padding: 20px 10px 10px;
      background: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      color: #23242a;
      font-size: 13px;
      letter-spacing: 0.05em;
      transition: 0.5s;
      z-index: 10;
    }

    .user {
      margin-left: -30px;
      font-size: 15px;
    }

}

@media (max-width: 385px) { 

  .promo-too {
    border: 6px solid rgb(212, 99, 0);
    margin-top: 20px;
  }

  .box {
    position: relative;
    width: 340px;
    height: 520px;
    background-color: #181717;
    margin: 10.625rem auto;
    border-radius: 8px;
  }

  .box form h1{
    color: #000000;
    font-size: 18px;
    }

    .box form .links {
      display: flex;
      grid-column-gap: 100px;
      -webkit-column-gap: 100px;
              column-gap: 100px;
      font-size: 10px;
    }

    .gender-category label{
      padding: 20px;
      font-size: 15px;
      color: #ffffff;
      }

    .box form input[type="submit"]
    {
      border: none;
      outline: none;
      padding: 9px 25px;
      background-color: #000000;
      color: #fff;
      cursor: pointer;
      font-size: 15px;
      border-radius: 4px;
      font-weight: 300;
      width: 150px;
      margin-top: 10px;
    }

    .box form .inputbox input{
      position: relative;
      width: 100%;
      padding: 20px 10px 10px;
      background: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      color: #23242a;
      font-size: 10px;
      letter-spacing: 0.05em;
      transition: 0.5s;
      z-index: 10;
    }

    .user {
      margin-left: -30px;
      font-size: 10px;
    }

}

@media (max-width: 419px) {



  .wrapp {

    .gender-category label{
      padding: 20px;
      font-size: 15px;
      color: #ffffff;
      }
   
    width: 98%; 
    display: flex; 
    margin-top: -140px;
    background-color: #0f0f0f;
    border-radius: 10px;
  }

 }

 @media (max-width:  361px) {

  .directors-one {
    width: 100%;
    margin-left: -20px;
   }

  .chrisprofile p{
    width: 90%;
    
    padding: 20px;
    text-align: start;
    font-size: 1.14rem;
    color: rgb(0, 0, 0);
  }

  .wrapp {
    width: 85%; 
    display: flex; 
    margin-left: 13px;
    margin-top: -140px;
    background-color: #0f0f0f;
    border-radius: 10px;
  }
 

 }

 
 @media (max-width:  769px) {

  .glosafe {
    width: 70%;
    margin-left: -20px;
}
} 

 @media (max-width:  774px) {

  .glosafe {
    width: 70%;
    margin-left: -20px;
}



.announce-two img {
  width: 238px;
  height: 310px; 
  box-shadow: 0 10px 8px rgb(22, 21, 20);
}



.announce-text-box h1 {
  text-align: start;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  margin-top: 2rem;
  font-size: 2.24rem;
  background-color: white;
  color: rgb(0, 0, 0);
}

.rowling .rowlingright .content p {
  font-size: 15px;
  text-align: start;
  padding-bottom: 15px;
}

.announce-text-box {
  text-align: start;
  margin-top: 2rem;
  font-size: 14px;
  background-color: white;
  color: rgb(0, 0, 0);
}


.ceos h4 {
  font-size: 2rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  margin-top: 2rem;
  
  background-color: white;
  color: rgb(0, 0, 0);
}


.home-too {
  min-height: 30vh;
  width: 100%;
  
}

.signupup {
  border: none;
  width: 25%;
  height: 30px;
  margin: auto;
  font-size: 10px;
  background-color: #000000;
  cursor: pointer;
  color: #08f7e3;
  border-radius: 10px;
}

 }

 @media (min-width:  769px) {

  .glosafe {
    display: none;
}



 }



 





.acct {
  background-color: black;
}
