@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400&display=swap");

body {
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  scroll-behavior: smooth;
}

.email {
  font-size: 18px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

:root {
  --mainColor: transparent;
  --mainColorLight: #29335c;
  --secondatyColor: #0b2b39;
  --textColor: #f6f4f2;
}

.nav-nav a {
  color: #000000;
}

header h3 {
  color: #ec6403;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.9rem;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding: 10px;
  background-color: orange;
  box-shadow: 0 15px 20px rgb(160, 154, 149);
  margin-bottom: 0px;
}

.nav a:hover {
  color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 6px;
}

.nav a {
  font-size: 1.5rem;
}

nav a {
  list-style: none;
  margin-left: 18px;
  margin-top: 20px;
  font-size: 20px;
}

.dropdown-menu {
  list-style: none;
  color: #beced5;
  position: relative;
  padding: 2rem;
  cursor: pointer;
}

.dropdown-list-list {
  display: block;
  border: 2px solid #ef5d03;
  text-align: start;
  color: #000000;
  list-style-type: none;
  position: absolute;
  left: 0;
  width: 230px;
  background-color: #fffefe;
}

.dropdown-list-list li:hover {
  height: 20px;
  cursor: pointer;
}

.dropdown-list .Link {
  border: 1px solid gray;
}

.dropdown-pop {
  color: #5abde7;
}

.dropdown-list .Link:hover {
  border: 1px solid white;
}

nav a {
  text-decoration: none;
  color: #fcf8f8;
  font-family: arial;
  text-transform: uppercase;
}

nav a:hover {
  text-decoration: underline;
}
.startlog {
  background-color: #000000;
  width: 100%;
  height: 100vh;
}
.logstart h1 {
  color: orangered;
  font-size: 5rem;
  padding: 10rem;
  font-family: Arial, Helvetica, sans-serif;
}

.signlog {
  margin: 20px;
  font-weight: bold;
  font-size: 1.4rem;
  cursor: pointer;
  width: 10%;
  height: 40px;
  border: none;
  background-color: rgb(245, 139, 0);
}

.signlog:hover {
  background-color: rgb(255, 255, 255);
}

.logstart p {
  color: #fff;
  font-size: 2rem;
  margin-top: -5.3em;
  font-family: Arial, Helvetica, sans-serif;
}

.famusic {
  margin-top: 2rem;
  font-size: 10rem;
  color: orangered;
}
.con-content {
  background-color: rgb(6, 20, 20);
  color: #fff;
  height: 120px;
}

.dirlink {
  color: blue;
  text-decoration: none;
}
.dirlink:hover {
  text-decoration: underline;

}

.dir-content {
  background-color: rgb(6, 20, 20);
  color: #fff;
  height: 340px;
}
.dir-concert-content {
  padding-top: 70px;
  font-size: 25px;
  width: 40%;
  margin-left: 50px;
}
.songs-content {
  background-color: rgb(6, 20, 20);
  color: #fff;
  height: 340px;
}



.songs-concert-content p {
  padding-top: 70px;
  font-size: 19px;
  width: 40%;
  margin-left: 50px;
}

.concert-content p {
  text-align: start;
  padding-top: 30px;
  font-size: 25px;
  width: 40%;
  margin: auto;
}

.reg-content {
  background-color: #b9b7b3;
  height: 340px;
}
.reg-content p {
  background-color: #b9b7b3;
  height: 340px;
}

.login-log {
  font-size: 28px;
  width: 200px;
  height: 60px;
  background-color: #000000;
  color: #da6200;
  margin-left: -1500px;
  margin-top: 60px;
  cursor: pointer;
}

.login-log:hover {
  transition: 0.4s ease-in-out;
  background-color: #ebe6e6;
  color: #000000;
}

.signupup {
  border: none;
  width: 20%;
  height: 70px;
  margin: auto;
  font-size: 25px;
  background-color: #000000;
  cursor: pointer;
  color: #08f7e3;
  border-radius: 10px;
}

.signupup:hover {
  background-color: #fff;
  color: #000000;
  text-decoration: underline;
  transition: 0.3s ease-in-out;
}

.con-Sign-up:hover {
  transition: 0.4s ease-in-out;
  background-color: #ebe6e6;
  color: #000000;
}

.con-reg-btn:hover {
  transition: 0.4s ease-in-out;
  background-color: #ebe6e6;
  color: #000000;
}

.dorcus-row {
  width: 80%;
  display: grid;
  margin-top: 11rem;
  margin-left: 4rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem 1.8rem;
}

.dorcus-row .right {
  display: flex;
  align-items: center;
}

.dorcus-row .right .content {
  padding-left: 20px;
  background-color: rgb(215, 213, 212);
}

.dorcus-row .right .content p {
  font-size: 1.5rem;
  line-height: 1.6rem;
  padding-bottom: 1rem;
}

.Sati-row {
  width: 80%;
  display: grid;
  margin-top: 11rem;
  margin-left: 4rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem 1.8rem;
}
.Sati-row .right {
  display: flex;
  align-items: center;
}
.Sati-row .right .content {
  padding-left: 20px;
  background-color: rgb(215, 213, 212);
}
.Sati-row .right .content p {
  font-size: 1.5rem;
  line-height: 1.6rem;
  padding-bottom: 1rem;
}

.Kasselie-row {
  width: 80%;
  display: grid;
  margin-top: 11rem;
  margin-left: 4rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem 1.8rem;
}

.Kasselie-row .right {
  display: flex;
  align-items: center;
}

.Kasselie-row .right .content {
  padding-left: 20px;
  background-color: rgb(215, 213, 212);
}
.Kasselie-row .right .content p {
  font-size: 1.5rem;
  line-height: 1.6rem;
  padding-bottom: 1rem;
}

.elkana-row {
  width: 80%;
  display: grid;
  margin-top: 11rem;
  margin-left: 4rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem 1.8rem;
}

.elkana-row .right {
  display: flex;
  align-items: center;
}
.elkana-row .right .content {
  padding-left: 20px;
  background-color: rgb(215, 213, 212);
}

.elkana-row .right .content p {
  font-size: 1.5rem;
  line-height: 1.6rem;
  padding-bottom: 1rem;
}

.peter-row {
  width: 80%;
  display: grid;
  margin-top: 11rem;
  margin-left: 4rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem 1.8rem;
}

.peter-row .right {
  display: flex;
  align-items: center;
}

.peter-row .right .content {
  padding-left: 20px;
  background-color: rgb(215, 213, 212);
}

.peter-row .right .content p {
  font-size: 1.5rem;
  line-height: 1.6rem;
  padding-bottom: 1rem;
}

.summit-row {
  width: 80%;
  display: grid;
  margin-top: 11rem;
  margin-left: 4rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem 1.8rem;
}

.summit-row .left {
  overflow: hidden;
}

.summit-row .right .content p {
  font-size: 1.5rem;
  line-height: 1.6rem;
  padding-bottom: 1rem;
}

.summit-row .right .content {
  padding-left: 20px;

  background-color: rgb(215, 213, 212);
}

.glojes {
  color: #fe7300;
  background-color: rgb(0, 0, 0);
  width: 150px;
  font-size: 23px;
  height: 100px;
  text-align: center;
  padding-top: 10px;
  border-radius: 60px;
  border: 5px solid #ff8400;
}

.glo-too {
  min-height: 60vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(rgb(4, 9, 30, 0.7), rgb(4, 9, 30, 0.7)),
    url(../images/crow.jpg);
}

.rules-too {
  min-height: 80vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(rgb(4, 9, 30, 0.7), rgb(4, 9, 30, 0.7)),
    url(../images/crowww.jpg);
}




.home-too {
  min-height: 80vh;
  width: 100%;
  box-shadow: 0 10px 8px rgb(22, 21, 20);
  background-position: center;
  background-size: cover;
  background-image: url(../images/crowww.jpg);
  background-repeat: no-repeat;

  transition: 6s;

  animation-name: animate;
  animation-direction: alternate-reverse;
  animation-play-state: running;

  animation-timing-function: ease-in-out;
  animation-duration: 30s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@keyframes animate {
  0% {
    background-image: url(../images/faith.jpg);
  }
  20% {
    background-image: url(../images/shm.jpg);
  }
  50% {
    background-image:url(../images/crowww.jpg);
  }
  60% {
    background-image: url(../images/csm.jpg);
  }
}

.concert-too {
  min-height: 80vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgb(4, 9, 30, 0.7)),
    url(../images/crow.jpg);
}

.songs {
  text-decoration: none;
}
.songs:hover {
  text-decoration: underline;
}
.concerttext-box p {
  width: 60%;
  color: white;
  font-size: 26px;
  text-align: center;
  font-family: sans-serif;
  margin: auto;
}
.additionright .additioncontent {
  width: 100%;
}



.adverthead {
  color: #ffffff;
}

.upup .signupup {
  margin-top: 28rem;
}



.additionright .additioncontent p {
  font-family: sans-serif;
  text-align: start;
  font-size: 22px;
  padding: 30px;
  width: 50%;
  margin: auto;
}
.advertcontent {
  font-family: sans-serif;
  text-align: start;
  font-size: 22px;
  padding: 30px;
  width: 50%;
  margin: auto;
}

.advert-row {
  display: column;
  background-color: #000000;
}

.text-box p {
  width: 210px;
  color: white;
  font-size: 28px;
  text-align: center;
  margin-left: 890px;
  font-family: Myanmar Text;
}

.homepara {
  
  color: white;
  font-size: 20px;
  width: 50%;
  text-align: center;
  margin: auto;
  font-family: Myanmar Text;
}


.promo-too {
  border: 10px solid rgb(212, 99, 0);
  margin-top: 20px;
}


.promotext-box p {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  color: white;
  font-size: 20px;
  padding: 2px;
  width: 100%;
  text-align: center;
  margin: auto;
  font-family: Myanmar Text;
}


.promotext-box h1 {
  padding: 12px;
  color: white;
  font-size: 1.25rem;
  padding: -90px;
  margin: auto;
  font-family: Futura Bk BT;
}

.rules-textbox p {
  padding: 20px;
  color: white;
  font-size: 22px;
  width: 50%;
  text-align: center;
  margin: auto;
  font-family: Myanmar Text;
}

.rules-textbox h1 {
  color: white;
  font-size: 6rem;
  text-align: center;
  margin: auto;
  font-family: Myanmar Text;
}

.text-box h1 {
  color: white;
  margin-top: 78px;
  font-size: 1.75rem;
  font-family: Futura Bk BT;
}

.hometext-box h1 {
  width: 80%;
  color: white;
  font-size: 3.75rem;
  margin-top: -60px;
  margin: auto;
  font-family: Futura Bk BT;
}
.concerttext-box h1 {
  width: 20%;
  color: white;
  font-size: 3.75rem;
  margin: auto;
  font-family: Futura Bk BT;
}
.text-box h2 {
  color: white;
  font-size: 2.15rem;
  font-family: Humanst521 Lt BT;
}
.hometext-box h2 {
  color: white;
  font-size: 2.15rem;
  font-family: Humanst521 Lt BT;
}
.concerttext-box h2 {
  color: white;
  font-size: 2.15rem;
  font-family: Humanst521 Lt BT;
}

.text-box {
  padding-top: 130px;
}

.concerttext-box {
  padding-top: 130px;
}
.about {
  width: 60%;
  align-items: center;
  padding: 2rem;
  margin: auto;
}
.aboutabout {
 
  font-size: 1.5rem;
  text-align: start;
}

.aboutabout h1 {
  text-align: center;
  background-color: black;
  color: orangered;
}

.our-tour {
  margin-top: 7.67rem;
  font-size: 1rem;
}

.tour-row {
  padding-top: 10px;
  width: 80%;
  display: flex;
  justify-content: center;
  column-gap: 140px;
  padding-top: 1.34%;
  margin: auto;
}

.sectionrow {
  background-color: #110534;
  width: 100%;
  margin: auto;
  margin-top: 30px;
}
.summitsectionrow {
  background-color: #110534;
  width: 100%;
  margin: auto;
  margin-top: 30px;
}

.rowling {
  width: 80%;
  max-width: 1170px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px 30px;
  margin: auto;
}

.summitrowling {
  width: 80%;
  max-width: 1170px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px 30px;
  margin: auto;
}
.rowling .rowlingleft {
  padding: 60px;
  overflow: hidden;
}

.summitrowling .summitrowlingleft {
  padding: 60px;
  overflow: hidden;
}

.summitrowlingleft p {
  margin-left: 50px;
  color: white;
  font-size: 23px;
}

.rowling .rowlingright {
  display: flex;
  align-items: center;
}

.summitrowling .summitrowlingright {
  display: flex;
  align-items: center;
}
.summitrowling .summitrowlingright .summitcontent {
  color: white;
  padding: 20px;
}

.rowling .rowlingright .content {
  color: white;
  
}

.summitrowling .summitrowlingright .summitcontent {
  color: white;
  padding: 20px;
}
.rowling .rowlingright .content p {
  font-size: 20px;
  text-align: start;
  padding-bottom: 15px;
}

.summitrowling .summitrowlingright .summitcontent p {
  font-size: 20px;
  line-height: 32px;
  text-align: start;
  padding-bottom: 15px;
}
.search-search {
  padding: 25px;
}

.search-bar {
  width: 40%;
  height: 65px;
  background-color: white;
  border: 3px solid #fff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  margin: auto;
}
.search-bar input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 25px;
  color: #000000;
}
.search-bar input::placeholder {
  color: #000000;
}

.search-bar button {
  height: 40px;
  width: 100%;
  margin-left: 400px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
}
.search-bar button .FaSearch {
  font-size: 30px;
  color: #000000;
}

.one-one {
  padding: 20px;
}
.adv {
  color: #ffffff;
  
  width: 70%;
  justify-content: center;
  column-gap: 140px;
  padding-top: 1.34%;
  margin: auto;
}
.announce-row {
  padding-top: 10px;
  width: 70%;
  display: flex;
  justify-content: center;
  column-gap: 140px;
  padding-top: 1.34%;
  margin: auto;
}

.videorow-textbox {
  width: 90%;
}

.videorow {
  padding-top: 10px;
  width: 80%;
  display: flex;
  justify-content: center;
  column-gap: 40px;
  padding-top: 1.34%;
  margin: auto;
}
.videohead h1 {
  color: orangered;
  font-size: 60px;
  padding: 20px;
}

.videoplayer {
  background-color: #060311;
  margin: 20px;
}

.directors-pop {
  padding: 20px;
}
.directors-one {
 width: 100%;
}

.advert-one {
  width: 100%;
}
.ceos h4 {
  font-size: 4rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  margin-top: 2rem;
  
  background-color: white;
  color: rgb(0, 0, 0);
}

.crowd-grace {
  padding-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 140px;
  padding-top: 1.34%;
  margin: auto;
}

.guidelines {
  padding-top: 10px;
  width: 60%;
  justify-content: center;
  column-gap: 140px;
  padding-top: 1.34%;
  margin: auto;
}

.rule-textbox {
  width: 600px;
  justify-content: center;
  text-align: start;
  display: flex;
  margin-left: 20px;
  padding: 10px;
  background-color: #0c0427;
  color: white;
  font-size: 19px;
}

.rule-textbox:hover {
  background-color: #fffbfb;
  color: rgb(0, 0, 0);
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.7s ease;
}

.rule-textbox p {
  padding: 20px;
}

.rules-icon {
  min-width: 60px;
  display: flex;
  height: 60px;
  background-color: #fa9420;
  color: rgb(0, 0, 0);
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 22px;
}

.major-rules-section {
background-color: #060311;
  width: 100%;
  margin: auto;
  margin-top: 30px;
  background-size: cover;
}

.con {
  background-color: #060311;
  color: #08f7e3;
  margin: auto;
  font-size: 30px;
}

.rls {
  background-color: #060311;
  color: #08f7e3;
  margin: auto;
  font-size: 30px;
}

.major-rules {
  padding: 70px;
  width: 30%;
  display: column;
  margin: auto;
}

.one-rule {
  display: flex;
}

.rules-row {
  padding-top: 10px;
  width: 70%;
  display: flex;
  justify-content: center;
  column-gap: 140px;
  padding-top: 1.34%;
  margin: auto;
}

.con-row {
  padding-top: 10px;
  width: 70%;
  display: flex;
  justify-content: center;
  column-gap: 140px;
  padding-top: 1.34%;
  margin: auto;
}

.directors-row {
  padding-top: 10px;
  width: 90%;
  display: flex;
  justify-content: center;
  column-gap: 140px;
  padding-top: 1.34%;
  margin: auto;
}
.announce-one {
  width: 100%;
}
.sponsors-one {
  width: 100%;
}
.sponsors-two-one {
  width: 100%;
}
.conrowrow-one {
  width: 100%;
}
.concert-one {
  width: 100%;
}

.directors-one {
  width: 100%;
}

.ment h1 {
  font-size: 64px;
  padding: 30px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.ment p {
  font-size: 1.34rem;
}

.guidelines-text-box {
  width: 64%;
  margin: auto;
  text-align: start;
  font-family: sans-serif;
  margin-top: 2rem;
  font-size: 1.34rem;
  background-color: white;
  color: rgb(0, 0, 0);
}
.guidelines-text-box h1 {
  text-align: center;
}

.announce-text-box h1 {
  text-align: start;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  margin-top: 2rem;
  font-size: 3.24rem;
  background-color: white;
  color: rgb(0, 0, 0);
}

.announce-text-box {
  text-align: start;
  margin-top: 2rem;
  font-size: 1.34rem;
  background-color: white;
  color: rgb(0, 0, 0);
}

.sponsors-text-box {
  font-family: sans-serif;
  margin-top: 2rem;
  font-size: 1.34rem;
}

.sponsors-two-text-box {
  font-family: sans-serif;
  margin-top: 2rem;
  font-size: 1.34rem;
}

.rulesrow-text-box {
  text-align: start;
  font-family: sans-serif;
  margin-top: 2rem;
  font-size: 1.34rem;
  background-color: white;
  color: rgb(0, 0, 0);
}
.con-text-box {
  box-shadow: 0 10px 8px rgb(65, 60, 56);
  text-align: start;
  font-family: sans-serif;
  margin-top: 2rem;
  font-size: 1.34rem;
  background-color: white;
  color: rgb(0, 0, 0);
}
.concert-text-box {
  text-align: start;
  font-family: sans-serif;
  margin-top: 2rem;
  font-size: 1.34rem;
  background-color: white;
  color: rgb(0, 0, 0);
}
.dirbox {
  margin: auto;
  padding: 30px;
  font-size: 1.34rem;
  color: rgb(0, 0, 0);
}

.chrisprofile p{
  width: 40%;
  margin: auto;
  padding: 30px;
  text-align: start;
  font-size: 1.34rem;
  color: rgb(0, 0, 0);
}

.andrewprofile {
  width: 40%;
  margin: auto;
  padding: 30px;
  text-align: start;
  font-size: 1.34rem;
  color: rgb(0, 0, 0);
}
.peterprofile {
  width: 40%;
  margin: auto;
  padding: 30px;
  text-align: start;
  font-size: 1.34rem;
  color: rgb(0, 0, 0);
}

.elknaprofile {
  width: 40%;
  margin: auto;
  padding: 30px;
  text-align: start;
  font-size: 1.34rem;
  color: rgb(0, 0, 0);
}
.dirbox h1 {
  margin: auto;
}

.directors-text-box {
  margin-top: 2rem;
  font-size: 1.34rem;
  background-color: white;
  color: rgb(0, 0, 0);
}

.one-one-text-box {
  background-color: #000000;
  color: orangered;
}

.one-one-text-box h1 {
  font-size: 2.34rem;
}

.one-one-text-box p {
  font-size: 1.34rem;
}

.two-text-box h1 {
  font-size: 2.34rem;
}
.three-text-box h1 {
  font-size: 2.34rem;
}

.glo-jesus {
  color: #09a29a;
  background-color: #021615;
  width: 767px;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  margin-top: -300px;
}

.summit-names {
  color: #000000;
}
.summit-names-names {
  padding-right: 1800px;
  margin-top: 5px;
}

.videorow-textbox {
  font-family: sans-serif;
  margin-top: 2rem;
  font-size: 1.34rem;
  color: white;
  text-align: start;
  margin: auto;
}

.sumit-names2 {
  padding-right: 1200px;
  margin-top: -310px;
}

.summit-last-list {
  margin-top: -310px;
}
.summit-names-names-names {
  padding-right: 1200px;
  margin-top: -310px;
  margin-left: 600px;
}

.summit-listing {
  margin-top: -230px;
  margin-left: 700px;
}

.summit-2022 {
  padding: 20px;
  color: white;
}

.summit-all-list {
  background-color: #000000;
  height: 600px;
  color: #e8d1d1;
  font-size: 20px;
}
.summit-rules p {
  margin-left: 150px;
  font-size: 20px;
  color: white;
  width: 60%;
}
.summit-rules-con {
  background-color: #000000;
}

.the-rules {
  font-size: 25px;
}

.text-profile {
  font-size: 23px;
}

.andrew-drew-profile {
  font-size: 23px;
}

.comments-all {
  font-size: 23px;
}
.about-one {
  margin-right: 1300px;
  font-size: 23px;
}

.about-two {
  position: absolute;
  margin-left: 400px;
  top: 243px;
  left: 800px;
  font-size: 23px;
}

.home-background {
  background-color: #f1e8e8;
}

.wrapper {
  margin: -250px auto;
  margin-top: -450px;
  width: 70%;
  margin-left: 320px;
}

.glo-glo {
  margin-top: 300px;
}
.wrapper p {
  background-color: #f4b803;
}

.wrapper-joh {
  margin: 445px auto;
  width: 70%;
  margin-left: 320px;
}

.wrapper4 {
  margin: 150px auto;
  width: 70%;
  margin-right: 285px;
}

.text-box {
  color: #000000;
}

.wrapper h1 {
  font-size: 80px;
}

.wrapper-joh h1 {
  font-size: 80px;
}

.wrapper-joh p {
  background-color: #f4b803;
  font-size: 25px;
}

.text-box-box p {
  color: rgb(0, 0, 0);
  font-size: 19px;
}
.text-box .propro {
  font-size: 62px;
}

.glo-wrapper h4 {
  color: #110534;
}

.wrapper p {
  font-size: 25px;
}
.text-box2 {
  color: #000000;
}

.text-box2 p {
  font-size: 22px;
}

.wrapper-2 {
  background-color: #ffffff;
  margin: 150px auto;
  margin-top: 500px;
  width: 70%;
  height: 405px;
  align-items: center;
}

.wrapper-2 p {
  font-size: 23px;
}

.wrapper-chris {
  background-color: #ebe6e6;
  margin: 145px auto;
  width: 40%;
  margin-left: 100px;
}
.wrapper-chris p {
  color: rgb(0, 0, 0);
  font-size: 26px;
}
.wrapper-chris h1 {
  color: rgb(0, 0, 0);
}

.wrapper-andrew {
  background-color: #ebe6e6;
  margin: 45px auto;
  width: 40%;
  margin-left: 100px;
}
.wrapper-andrew h1 {
  color: rgb(0, 0, 0);
}

.wrapper-andrew p {
  color: rgb(0, 0, 0);
  font-size: 26px;
}

.wrapper-peter p {
  color: rgb(0, 0, 0);
  font-size: 26px;
}

.wrapper-peter h1 {
  color: rgb(0, 0, 0);
}

.wrapper-peter {
  background-color: #ebe6e6;
  margin: 45px auto;
  margin-top: -1232px;
  width: 40%;
  margin-left: 1100px;
}

.wrapper-elkana {
  background-color: #ebe6e6;
  margin: 45px auto;
  margin-top: -725px;
  width: 40%;
  margin-left: 1100px;
}
.wrapper-elkana h1 {
  color: rgb(0, 0, 0);
}

.wrapper-elkana p {
  color: rgb(0, 0, 0);
  font-size: 25px;
}

.wrapper-concert {
  background-color: #e1e8ec;
  width: 900px;
  margin-left: 250px;
  margin-top: 178px;
  margin-left: 48px;
  border: 5px solid #fffefe;
}
.wrapper-concert p {
  font-size: 25px;
}

.schedule-wrapper {
  background-color: #000000;
  margin-top: 190px;
}

.wrapper-side-two-concert {
  background-color: #e1e8ec;
  width: 900px;
  margin-left: 250px;
  margin-top: -1075px;
  margin-left: 1060px;
  border: 5px solid #fffefe;
}

.col {
  width: 1800px;
  justify-content: space-between;
  margin-left: 120px;
  font-size: 20px;
  margin-top: 100px;
}
.activities {
  width: 1800px;
  justify-content: space-between;
  margin-left: 120px;
  font-size: 20px;
  margin-top: 100px;
  cursor: pointer;
}

.members-roll:hover {
  background-color: rgb(170, 175, 174);
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.activities-roll:hover {
  background-color: rgb(0, 0, 0);
  color: white;
  transition: 0.2s ease-in-out;
}

.one:hover {
  background-color: rgb(0, 0, 0);
  color: orange;
  transition: 0.2s ease-in-out;
}

.event-name:hover {
  background-color: rgb(218, 116, 0);
  color: rgb(0, 0, 0);
  transition: 0.2s ease-in-out;
}

.vanue:hover {
  background-color: rgb(218, 116, 0);
  color: rgb(0, 0, 0);
  transition: 0.2s ease-in-out;
}

.date:hover {
  background-color: rgb(218, 116, 0);
  color: rgb(0, 0, 0);
  transition: 0.2s ease-in-out;
}

.time:hover {
  background-color: rgb(218, 116, 0);
  color: rgb(0, 0, 0);
  transition: 0.2s ease-in-out;
}

.number:hover {
  background-color: rgb(248, 112, 0);
  color: rgb(0, 0, 0);
  transition: 0.2s ease-in-out;
}

.first-name:hover {
  background-color: rgb(0, 0, 0);
  color: orange;
  transition: 0.2s ease-in-out;
}
.tone:hover {
  background-color: rgb(0, 0, 0);
  color: orange;
  transition: 0.2s ease-in-out;
}
.number:hover {
  background-color: rgb(0, 0, 0);
  color: orange;
  transition: 0.2s ease-in-out;
}
.p-number:hover {
  background-color: rgb(0, 0, 0);
  color: orange;
  transition: 0.2s ease-in-out;
}

.your-account {
  margin-top: 50px;
  font-family: MV Boli;
}
.activities-tittle h1 {
  font-family: Microsoft YaHei UI Light;
}
.list {
  font-family: Microsoft YaHei UI Light;
  width: 450px;
  height: 70px;
  background-color: #000000;
  color: #feffff;
}
.activities-list {
  font-family: Microsoft YaHei UI Light;
  width: 400px;
  height: 70px;
  background-color: #9ba3a1;
  color: #000000;
}
.song-song-list {
  font-size: 25px;
  font-family: monospace;
  width: 450px;
  height: 70px;
  background-color: #000000;
  color: #fcfcfc;
}

.edit-btn {
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.edit-btn {
  display: flex;
}
.view {
  width: 100px;
  height: 50px;
  font-size: 20px;
  color: rgb(251, 248, 248);
  background-color: #000000;
  border-radius: 10px;
  cursor: pointer;
}
.edit {
  width: 100px;
  height: 50px;
  font-size: 20px;
  color: rgb(0, 0, 0);
  background-color: #bdcef0;
  border-radius: 10px;
  cursor: pointer;
}

.delect {
  width: 100px;
  height: 50px;
  font-size: 20px;
  color: white;
  background-color: #db0404;
  border-radius: 10px;
  cursor: pointer;
}
.wrapper-side-two-concert p {
  font-size: 25px;
}
.youth-wrapper4 {
  background-color: #0b2b39;
}

.wel {
  width: 100%;
  height: 100px;
  background-color: #f8dfc6;
}

.wel h1 {
  margin-right: 1600px;
  padding: 35px;
}

.wel button {
  width: 268px;
  height: 60px;
  background-color: #000000;
  border-radius: 10px;
  margin-left: 1640px;
  margin-top: 20px;
  color: #fffffe;
  font-size: 26px;
  cursor: pointer;
}
.wel button:hover {
  background-color: rgb(93, 255, 255);
  color: #000000;
}

.kesselie-a {
  margin-top: 20px;
}

.andrew-reg-link {
  background-color: #cdf7ed;
  margin-top: 150px;
}
.andrew-reg-link h1 {
  color: #000000;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.wrapper-choirlist {
  background-image: url(../images/crown.jpg);

  background-size: cover;
  background-repeat: no-repeat;
}
.additonal-rules {
  background-image: url(../images/dackcgt.jpg);
}

.glo-wrapper {
  background-color: #c77c02;
  max-width: 90%;
  width: 15%;
  font-size: 50px;
  border: 5px solid #000000;
  margin-left: 2px;
  left: 100px;
  backdrop-filter: blur(30px);
  margin-top: -530px;
  left: 100px;
}

.glo-wrapper h1 {
  top: 200px;
  border: 5px solid #000000;
}

.sign-today {
  background-color: rgb(6, 20, 20);
  padding-top: rem;
}
.sign-up {
  width: 900px;
}

.glojes-name {
  color: #067aac;
}

.additonal-rules-text-box {
  font-size: 22px;
}

.additonal-rules-text-box p {
  color: #f6f2f2;
}

.additonal-rules-text-box h3 {
  color: #f6f2f2;
}

.icons-youtube {
  color: rgb(255, 254, 254);
  width: 80px;
  height: 30px;
  cursor: pointer;
}

.icons-facebook {
  color: rgb(255, 255, 255);
  width: 80px;
  height: 30px;
  cursor: pointer;
}
.icons-whatsapp {
  color: rgb(255, 255, 255);
  width: 80px;
  height: 30px;
  cursor: pointer;
}

.s-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.button-nav {
  background-color: #000000;
}

.glojes-button-name {
  color: #ffffff;
  font-size: 20px;
  padding: 50px;
}

.glojes-nav-but {
  background-color: #000000;
  height: 200px;
}

.top-glojes {
  background-color: #021615;
}

.top-glojes h1 {
  color: #067aac;
  font-size: 70px;
}
.top-glojes h2 {
  color: #dfecf2;
  font-size: 40px;
}

.top-glojes-glo {
  background-color: #000000;
  color: #067aac;
  font-size: 90px;
}

.text-box-box-box p {
  color: #e8d1d1;
  font-size: 24px;
}

.text-box-box p {
  color: #000000;
}

.face-tube {
  top: 100px;
}
.text-box .eedmondedmond-name {
  font-size: 35px;
}
.text-box .marinagono-name {
  font-size: 35px;
}
.eedmondedmond-wrapper {
  margin-right: 1640px;
}

.marinagono-wrapper {
  position: absolute;
  margin-left: 150px;
  top: 980px;
  left: 450px;
}

.scrollbar {
  background-color: #edf3f3;
  height: 300px;
  width: 40%;
  margin: auto;
  font-family: Arial, Helvetica, sans-serif;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 15px;
}
::-webkit-scrollbar-track {
  background-color: #d6d5d8;
}
::-webkit-scrollbar-thumb {
  background: #000000;

  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #000000;
}

.sponsorheading h1 {
  font-size: 39px;
  margin: 30px;
}
.sponsorheading p {
  font-size: 23px;
  text-align: start;
  width: 80%;
  margin: auto;
}
.people {
  width: 20%;
  height: 40%;
  background-color: #067aac;
  margin: auto;
}

.elkana-wrapper {
  position: absolute;
  margin-left: 150px;
  top: 980px;
  left: 980px;
}

.text p {
  font-size: 20px;
}

.text-box .elkana-name {
  font-size: 35px;
}

.rule-head {
  background-color: #067aac;
}

.sponsors-meet {
  background-image: url(../images/crowdcrowdcrowd.jpg);
  width: 100%;
  height: 529px;
  background-repeat: no-repeat;
  background-size: cover;
}

.sponsors-meet h1 {
  background-color: #000000;
  color: #067aac;
}

.sponsors-meet {
  font-size: 30px;
  color: #beced5;
  box-shadow: 0 15px 20px rgb(240, 157, 89);
}

.button-button {
  position: absolute;
  margin-left: 250px;
  top: 1900px;
  left: -255px;
}

.award-text-box-box p {
  font-size: 30px;
}

.honor-list {
  background-color: #067aac;
}

.concerts-con {
  width: 390px;
  background-color: #000000;
  color: #f3f6f6;
}
.reg-button {
  width: 200px;
  background-color: #000000;
  height: 50px;
  margin-right: 1300px;
  margin-top: 30px;
  border-radius: 20px;
  list-style: none;
}

.Sign-btn {
  width: 200px;
  background-color: #000000;
  height: 50px;
  margin-right: 100px;
  border-radius: 20px;
  list-style: none;
}

.reg-btn-reg-now {
  font-size: 30px;
  text-decoration: none;
  color: #ec6403;
}

.reg-btn-reg-now:hover {
  color: #5abde7;
}

.Sign-up {
  text-decoration: none;
  font-family: arial;
  font-size: 20px;
  color: #ec6403;
}

.Sign-up:hover {
  color: #5abde7;
}

.reg-btn-reg-now :hover {
  color: #d5700a;
}

.concerts-p {
  color: white;
}

.pro {
  color: white;
}

.song-name {
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.song-genre {
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.songs-roll {
  background-color: #f3d3a2;

  cursor: pointer;
}

.songs-roll:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(170, 248, 231);
}
.digit:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(245, 201, 7);
}
.song-table {
  margin-left: 320px;
}

.digit {
  color: #ffffff;
  background-color: #09151b;
  font-size: 26px;
}
.with-andrew :hover {
  color: #d5700a;
}

.with-andrew {
  font-size: 24px;
}

.rule-parent {
  background-color: rgb(6, 20, 20);
}
.rule-child {
  color: #fff;
  padding-top: 40px;
  text-align: start;
  margin-left: 7rem;
  font-size: 22px;
  width: 50%;
}

.choir-tip {
  background-image: url(../images/whitesea.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 200px;
  font-size: 25px;
}

.slider {
  background-color: #000000;
  width: 100% auto;
  overflow: hidden;
  border: 5px solid #000000;
}

.video-slider {
  background-color: #000000;

  width: 100% auto;
  overflow: hidden;
  border: 5px solid #000000;
}

.images {
  display: flex;
  width: 100%;
  animation: 20s slider infinite;
}

.images img {
  width: 100%;
}

.images input {
  display: none;
}

.liberation-text-box {
  background-color: #faedd1;
  font-size: 1.4rem;
  cursor: pointer;
}

.contact {
  position: relative;
  min-height: 100vh;
  padding: 50px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #010e0d;
  background-size: cover;
}

.contact .content {
  max-width: 800px;
  text-align: center;
}
.contact .content h2 {
  font-size: 36px;
  font-weight: 500;
  color: #fff;
}

.contact .content p {
  font-weight: 300;
  color: #fff;
}
.container-ner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.container-ner .contact-info {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.container-ner .contact-info .box-box {
  position: relative;
  padding: 20px 0;
  display: flex;
}
.container-ner .contact-info .box-box .icons {
  min-width: 60px;
  height: 60px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 22px;
}
.container-ner .contact-info .box-box .text {
  display: flex;
  margin-left: 20px;
  font-size: 18px;
  color: #fff;
  flex-direction: column;
  font-weight: 200;
}

.container-ner .contact-info .box-box .h3 {
  font-weight: 500;
  color: #067aac;
}

.contact-form {
  width: 40%;
  padding: 40px;
  background-color: #fff;
}

.contact-form h2 {
  font-size: 30px;
  color: #110534;
  font-weight: 500;
}

.contact-form .inputbox {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.contact-form .inputbox input,
.contact-form .inputbox textarea {
  font-size: 16px;
  width: 100%;
  padding: 5px 0;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}

.contact-form .inputbox span {
  position: absolute;
  left: 0;
  font-size: 16px;
  padding: 5px 0;
  margin: -20px 0;
  pointer-events: none;
  transition: 0.6s;
  color: #067aac;
}

.contact-form .inputbox input:focus ~ span,
.contact-form .inputbox textarea:focus ~ span {
  color: #eb1102;
  margin-top: -1px;
  font-size: 24px;
  transform: translateY(-20px);
}

.inputbox .submit-mit {
  width: 100px;
  height: 60px;
  border: none;
  background-color: #d2f7f5;
  color: #000000;
  border: none;
  font-size: 20px;
  border-radius: 30px;
  padding: 10px;
  font-size: 19px;
  cursor: pointer;
}
.inputbox .submit-mit:hover {
  background-color: #5eeee2;
  transition: 0.6s;
  color: #000000;
}

.profile {
  min-width: 60%;
  min-width: 80%;
  min-height: 500px;
  background-color: #d5d0c9;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px 30px;
  margin: auto;
}

.profile .profile-left {
  width: 40%;
  min-height: 500px;
  background-color: #08f7e3;
}

.profile .profile-right {
  width: 100%;
  min-height: 500px;
  background-color: #110534;
}



.direclist {
  background-color: #a5d5e9;
}


.tableble {
  background-color: rgb(203, 245, 239);
  }

.tablename:hover {
 background-color: rgb(247, 205, 127);
 transition: 0.3s ease-in-out;
}

.tab {
width: 100%;
}

.propro {
  background-color: #000000;
}


.probox {
  background-color: #060311;
  margin-top: 90px;
  padding: 8px;
}

.poptab { 
  background-color: #cec1c1;
  color: #a4ece6;
}


.propro h2{
  color: orange;
  font-size: 1.9rem;
  padding: 60px;
  margin-left: 2rem;
 }
.proname {
  color: #08f7e3;
  font-size: 1.8rem;
  margin-left: 1.6rem;
} 

.avaimage {
  margin-left: 6.7rem;
  margin-top: -50px;
}

.gendergen {
  background-color: #090222;
  color: #eff8f4;
  font-size: 1.5rem;
}